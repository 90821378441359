import React, { useState,useEffect ,useContext} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../constant";
import { useTranslation } from 'react-i18next'; 
import { AuthContext } from "../context/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import htmlToPlainText from "../htmltotext";

const ProductCard = ({ image, title, price }) => {
  return (
    <div className="grid grid-cols-2 gap-0 rounded-lg shadow-md">
      <div className="bg-white p-4 ">
        <img src={image} alt={title} className="mb-2 h-[100px]" />
      </div>
      <div className="bg-white p-4">
        <h3 className="text-lg font-semibold">{title}</h3>
        <h2 className="text-lg font-semibold">${price}</h2>
      </div>
    </div>
  );
};

const ProductList = ({ filteredProducts }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {/* Render product cards */}
      {filteredProducts.map((product) => (
        <ProductCard key={product.id} image={product.images[0]} title={htmlToPlainText(product.title)} price={product.price} />
      ))}
    </div>
  );
};



const Subscriptions = () => {
  const navigate = useNavigate();
  const {cart, addBundleToCart, removeFromCart, currentUser, getUserData } = useContext(AuthContext);
  const [topics, setTopics] = useState([]);
  const [subscription_price, setSubscriptionPrice] = useState(0);

    useEffect(() => {
      fetchSubscriptions();
    }, []);
  
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api_subscription.php`);
        const bookData = response.data;
       
        if (Array.isArray(bookData)) {
          setAllTopics(bookData);
          setTopics([bookData[0]]);
          setSubscriptionPrice(bookData[0].price);
          handleFilterChange(bookData[0]);
         
        } else {
          console.error('Expected an array of books');
        }
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };
    const [allTopics,setAllTopics] = useState([]); // Array of all topics
  
    const handleTopicChange = (topic) => {
      console.log(topic);
      setTopics([topic]);
      setSubscriptionPrice(topic.price);
       handleFilterChange(topic); // Set topics array with only the selected topic
      
    };
  const [products,setProduct] = useState([]);
  
  

  const purchase = async (e,pro) => {
    e.preventDefault();
    console.log(pro);
    if(currentUser){
      navigate('/pages/Stripe',{state:{
        product:pro,
        amount:subscription_price,
        type:"subscription"
       }} );
            }else{
              toast.error(`Your need to login first`);
            }   
    // addBundleToCart(pro);
 
  };

  const handleFilterChange = (selectedTopics) => {
    console.log(selectedTopics);
    console.log(selectedTopics.books);
    setProduct(selectedTopics.books)
    
    console.log(products);
   
  };

  const {t}=useTranslation();

  return (
    <div>
     <div className="container mx-auto px-4 py-8 lg:flex w-full">
  <div className="pr-4 collection-filter lg:w-[25%]">
  <div className="bg-gray-100 p-4  sticky top-0 z-50">
        {/* Your filters content goes here */}
        <h2 className="text-lg font-semibold mb-2 col-span-2">{t('buy_sub')}</h2>
       <div className="grid grid-cols-2 gap-4">
        {allTopics.map((topic) => (
    <label key={topic.id} className="inline-flex items-center">
        <input
            type="checkbox"
            className="hidden"
            onChange={() => handleTopicChange(topic)}
            checked={topics.includes(topic)}
        />
        <span className={`${
            topics.includes(topic)
                ? "bg-red-500 text-white"
                : "bg-black-200 text-white-700"
            } px-4 flex py-2 border text-[12px] rounded-lg focus:outline-none`}>
            {topic.title}
        </span>
    </label>
))}
</div>
      </div>
    {/* <Filters onFilterChange={handleFilterChange} /> */}
  </div>
  <div className="collection-prod lg:w-[60%]">
    <ProductList filteredProducts={products} />
  </div>
  <div className="pl-4 total-price lg:w-[15%]">
  <div className="bg-gray-100 p-4 sticky top-0 z-50">
      <h2 className="text-lg font-semibold mb-2">{t('sub_price')}: ${subscription_price}</h2>
       <button className="bg-blue-500 text-white px-4 py-2 rounded-lg focus:outline-none" onClick={(e) => purchase(e, topics)}>{t('buy_sub')}</button> 
    </div>
  </div>
</div>
    </div>
  );
};

export default Subscriptions;
