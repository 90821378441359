
// import React from "react";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Navbar';
import Door from "./Door";
import Footer from "./Footer";
import "./i18n";
import {AuthContextProvider} from '../src/context/AuthContext';

const App=()=>{
   
    return(
        <>
         <AuthContextProvider>
        <Router>    
                <div className='app max-w-[1600px] m-auto'>
        <Navbar/>
        <Footer/>
        </div>
        </Router> 
        </AuthContextProvider>
        </> 
    )
    
};


  
  export default App;