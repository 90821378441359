import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
    { code: 'he', lang: 'Hebrew' },
  { code: 'en', lang: 'English' },
 
];

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir(lng); // Update document direction based on language
  };

  useEffect(() => {
    document.body.dir = i18n.dir(); // Set initial document direction based on current language
  }, [i18n.language]); // Update when language changes

  return (
    <div className="language-selector">
      <select
        value={i18n.language}
        onChange={(e) => changeLanguage(e.target.value)}
      className=' px-2 py-1 m-2 divide-y divide-gray-100 rounded-lg shadow bg-[#3b200f] divide-gray-600'
      >
        {languages.map((lng) => (
          <option key={lng.code} value={lng.code}>
            {lng.lang}
          </option>
        ))}
      </select>
    </div>
  );
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  return (
    <div className="">
     <LanguageSelect />     
    </div>
  );
};

export default LanguageSelector;
