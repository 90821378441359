import React, { useState } from 'react';
import { Worker, Viewer, defaultLayoutPlugin } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { highlightPlugin, HighlightArea } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { version as pdfjsVersion } from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist';
import Tesseract from 'tesseract.js';

// Set the worker source URL
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

const PdfApp = () => {
  const [word, setWord] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedResult, setSelectedResult] = useState(null);
  const [ocrCorrection, setOcrCorrection] = useState(false);
  const [ktivMaleChaser, setKtivMaleChaser] = useState(false);

  const [pdfFiles, setPdfFiles] = useState([
    'http://localhost/serve_pdf.php?file=50..pdf',
    'http://localhost/serve_pdf.php?file=דוגמא ביאורי רשי.pdf'
  ]);

  const [adjacent, setAdjacent] = useState(false);
  const [prefix, setPrefix] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const [distance, setDistance] = useState(0);
  const [alternatives, setAlternatives] = useState('');
  const [exclude, setExclude] = useState('');
  const [wordCompletion, setWordCompletion] = useState('');
  const [firstPageOnly, setFirstPageOnly] = useState(false);


  const handleWordChange = (event) => {
    setWord(event.target.value);
  };
  

  const handleSearch = async () => {
    if (!word) {
      alert("Please enter a word to search");
      return;
    }
  
    setLoading(true);
    setError('');
    setResults([]);
    setSelectedResult(null);
  
    let searchResults = [];
  
    try {
      for (const file of pdfFiles) {
        const loadingTask = pdfjs.getDocument(file);
        const pdf = await loadingTask.promise;
  
        const startPage = firstPageOnly ? 1 : 1;
        const endPage = firstPageOnly ? 1 : pdf.numPages;
  
        for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const textContent = await page.getTextContent();
          const textItems = textContent.items.map(item => item.str);
          let text = textItems.join(' ');
  
          if (ocrCorrection) {
            const viewport = page.getViewport({ scale: 1.0 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            await page.render({ canvasContext: context, viewport }).promise;
            const { data: { text: ocrText } } = await Tesseract.recognize(canvas, 'eng');
            text = ocrText;
          }
  
          // Apply search filters
          let matches = applyFilters(text, word, {
            adjacent,
            prefix,
            ordered,
            distance,
            alternatives,
            exclude,
            wordCompletion
          });
  
          matches.forEach(match => {
            searchResults.push({
              file,
              page: pageNum,
              index: match.index,
              text: match.context,
              matchText: match.matchText
            });
          });
        }
      }
  
      setResults(searchResults);
    } catch (err) {
      console.error('Error searching PDF files:', err);
      setError('An error occurred while searching the PDF files.');
    } finally {
      setLoading(false);
    }
  };
  // const handleSearch = async () => {
  //   if (!word) {
  //     alert("Please enter a word to search");
  //     return;
  //   }

  //   setLoading(true);
  //   setError('');
  //   setResults([]);
  //   setSelectedResult(null);

  //   let searchResults = [];

  //   try {
  //     for (const file of pdfFiles) {
  //       const loadingTask = pdfjs.getDocument(file);
  //       const pdf = await loadingTask.promise;

  //       for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //         const page = await pdf.getPage(pageNum);
  //         const textContent = await page.getTextContent();
  //         const textItems = textContent.items.map(item => item.str);
  //         const text = textItems.join(' ');

  //         // Apply search filters
  //         let matches = applyFilters(text, word, {
  //           adjacent,
  //           prefix,
  //           ordered,
  //           distance,
  //           alternatives,
  //           exclude,
  //           wordCompletion
  //         });

  //         matches.forEach(match => {
  //           searchResults.push({
  //             file,
  //             page: pageNum,
  //             index: match.index,
  //             text: match.context,
  //             matchText: match.matchText
  //           });
  //         });
  //       }
  //     }

  //     setResults(searchResults);
  //   } catch (err) {
  //     console.error('Error searching PDF files:', err);
  //     setError('An error occurred while searching the PDF files.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const applyFilters = (text, word, filters) => {
    let matches = [];
  
    if (filters.ktivMaleChaser) {
      text = ktivMaleChaserTransform(text);
      word = ktivMaleChaserTransform(word);
    }
  
    if (filters.alternatives) {
      const alternatives = filters.alternatives.split('|');
      for (const alt of alternatives) {
        matches = matches.concat(searchWordInText(text, alt, filters));
      }
    } else {
      matches = searchWordInText(text, word, filters);
    }
  
    if (filters.exclude) {
      const excludeWords = filters.exclude.split(' ');
      matches = matches.filter(match => {
        return !excludeWords.some(excludeWord => match.context.includes(excludeWord));
      });
    }
  
    return matches;
  };
  
  const searchWordInText = (text, word, filters) => {
    let matches = [];
    let regexString = word;

    if (filters.wordCompletion) {
      regexString = regexString.replace(/\*/g, '\\w*').replace(/\?/g, '\\w');
    }

    if (filters.prefix) {
      regexString = '\\b' + regexString;
    }

    if (filters.adjacent) {
      regexString = '\\b' + regexString + '\\b';
    }

    const regex = new RegExp(regexString, 'g');

    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push({
        index: match.index,
        context: text.substring(Math.max(0, match.index - 30), match.index + 30),
        matchText: match[0] // The exact matched text
      });
    }

    return matches;
  };
  const ktivMaleChaserTransform = (text) => {
    const chaserToMaleMap = {
      'א': 'א', // Aleph
      'ב': 'ב', // Bet
      'ג': 'ג', // Gimel
      'ד': 'ד', // Dalet
      'ה': 'ה', // He
      'ו': 'ו', // Vav
      'ז': 'ז', // Zayin
      'ח': 'ח', // Het
      'ט': 'ט', // Tet
      'י': 'י', // Yod
      'כ': 'כ', // Kaf
      'ך': 'ך', // Final Kaf
      'ל': 'ל', // Lamed
      'מ': 'מ', // Mem
      'ם': 'ם', // Final Mem
      'נ': 'נ', // Nun
      'ן': 'ן', // Final Nun
      'ס': 'ס', // Samekh
      'ע': 'ע', // Ayin
      'פ': 'פ', // Pe
      'ף': 'ף', // Final Pe
      'צ': 'צ', // Tsadi
      'ץ': 'ץ', // Final Tsadi
      'ק': 'ק', // Qof
      'ר': 'ר', // Resh
      'ש': 'ש', // Shin
      'ת': 'ת'  // Tav
    };
  
    let transformedText = text;
    for (const [chaser, male] of Object.entries(chaserToMaleMap)) {
      transformedText = transformedText.replace(new RegExp(chaser, 'g'), male);
    }
  
    return transformedText;
  };
  

  return (
    <div>
      <h1>PDF Word Search</h1>
      <input type="text" value={word} onChange={handleWordChange} placeholder="Search word" />
      <div>
        <label>
          <input type="checkbox" checked={adjacent} onChange={() => setAdjacent(!adjacent)} />
          Adjacent Words (-)
        </label>
        <label>
          <input type="checkbox" checked={prefix} onChange={() => setPrefix(!prefix)} />
          Prefix (+)
        </label>
        <label>
          <input type="checkbox" checked={ordered} onChange={() => setOrdered(!ordered)} />
          Keep Words in Order (&)
        </label>
        <label>
          Distance between Words:
          <input type="number" value={distance} onChange={(e) => setDistance(parseInt(e.target.value, 10))} />
        </label>
        <label>
          Alternative Words (word1|word2):
          <input type="text" value={alternatives} onChange={(e) => setAlternatives(e.target.value)} />
        </label>
        <label>
          Exclude Words (--):
          <input type="text" value={exclude} onChange={(e) => setExclude(e.target.value)} />
        </label>
        <label>
          Word Completion (*, ?):
          <input type="text" value={wordCompletion} onChange={(e) => setWordCompletion(e.target.value)} />
        </label>
        <label>
          <input type="checkbox" checked={firstPageOnly} onChange={() => setFirstPageOnly(!firstPageOnly)} />
          Search in First Page Only
        </label>
        <label>
          <input type="checkbox" checked={ocrCorrection} onChange={() => setOcrCorrection(!ocrCorrection)} />
          OCR Correction
        </label>
        <label>
          <input type="checkbox" checked={ktivMaleChaser} onChange={() => setKtivMaleChaser(!ktivMaleChaser)} />
          Ktiv Male V'Chaser
        </label>
      </div>
      <button onClick={handleSearch} disabled={loading}>Search</button>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        {results.length > 0 ? (
          results.map((result, index) => (
            <div key={index} onClick={() => setSelectedResult(result)}>
              <p>File: {result.file}</p>
              <p>Page: {result.page}</p>
              <p>Index: {result.index}</p>
              <p>Context: {result.text}</p>
            </div>
          ))
        ) : (
          !loading && <p>No results found</p>
        )}
      </div>
  
      {/* {selectedResult && (
        <div style={{ marginTop: '20px' }}>
          <h2>Viewing: {selectedResult.file}</h2>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
            <Viewer
              fileUrl={selectedResult.file}
              defaultScale={1.5}
              initialPage={selectedResult.page - 1}
              plugins={[
                highlightPluginInstance
              ]}
            />
          </Worker>
        </div>
      )} */}
    </div>
  );
  
  
  // return (
  //   <div>
  //     <h1>PDF Word Search</h1>
  //     <input type="text" value={word} onChange={handleWordChange} placeholder="Search word" />
  //     <div>
  //       <label>
  //         <input type="checkbox" checked={adjacent} onChange={() => setAdjacent(!adjacent)} />
  //         Adjacent Words (-)
  //       </label>
  //       <label>
  //         <input type="checkbox" checked={prefix} onChange={() => setPrefix(!prefix)} />
  //         Prefix (+)
  //       </label>
  //       <label>
  //         <input type="checkbox" checked={ordered} onChange={() => setOrdered(!ordered)} />
  //         Keep Words in Order (&)
  //       </label>
  //       <label>
  //         Distance between Words:
  //         <input type="number" value={distance} onChange={(e) => setDistance(parseInt(e.target.value, 10))} />
  //       </label>
  //       <label>
  //         Alternative Words (word1|word2):
  //         <input type="text" value={alternatives} onChange={(e) => setAlternatives(e.target.value)} />
  //       </label>
  //       <label>
  //         Exclude Words (--):
  //         <input type="text" value={exclude} onChange={(e) => setExclude(e.target.value)} />
  //       </label>
  //       <label>
  //         Word Completion (*, ?):
  //         <input type="text" value={wordCompletion} onChange={(e) => setWordCompletion(e.target.value)} />
  //       </label>
  //     </div>
  //     <button onClick={handleSearch} disabled={loading}>Search</button>
  //     {loading && <p>Loading...</p>}
  //     {error && <p style={{ color: 'red' }}>{error}</p>}
  //     <div>
  //       {results.length > 0 ? (
  //         results.map((result, index) => (
  //           <div key={index} onClick={() => setSelectedResult(result)}>
  //             <p>File: {result.file}</p>
  //             <p>Page: {result.page}</p>
  //             <p>Index: {result.index}</p>
  //             <p>Context: {result.text}</p>
  //           </div>
  //         ))
  //       ) : (
  //         !loading && <p>No results found</p>
  //       )}
  //     </div>

  //     {selectedResult && (
  //       <div style={{ marginTop: '20px' }}>
  //         <h2>Viewing: {selectedResult.file}</h2>
  //         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
  //           <Viewer
  //             fileUrl={selectedResult.file}
  //             defaultScale={1.5}
  //             initialPage={selectedResult.page - 1}
  //             plugins={[
  //               highlightPluginInstance
  //             ]}
  //           />
  //         </Worker>
  //       </div>
  //     )}
  //   </div>
  // );
};

export default PdfApp;
