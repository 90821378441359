import React, { useState, useEffect } from "react";
import { FaUsers, FaBook, FaUser, FaTypo3, FaTable } from 'react-icons/fa';
import BASE_URL from "../constant";
import axios from "axios";


const Dashboard = () => {
  // Sample data (replace with actual data from your backend or state)
  const [totalVisitors,settotalVisitor] = useState(0);
  const [totalUsers,settotalUsers] = useState(0);
  const [totalBooks,settotalBooks] =useState(0);
  const [users,setUsers] = useState( [
   
  ]);

  useEffect(() => {
    fetchUsers();
    fetchBooks();
    fetchCategories();
}, []);


const fetchBooks = async () => {
    try {
        let url = `${BASE_URL}/api_book.php/books`;
        
        const response = await axios.get(url);
        const bookData = response.data;
        console.log(response.data);
        if (Array.isArray(bookData)) {
          settotalBooks(bookData.length);
            // sliderData1(bookData);
            // extractTopics(bookData);
        } else {
            console.error('Expected an array of books');
        }
    } catch (error) {
        console.error('Error fetching books:', error);
    }
};
const fetchCategories = async () => {
  try {
      let url = `${BASE_URL}/api_categories.php`;
     
      const response = await axios.get(url);
      const bookData = response.data;
      console.log(response.data);
      if (Array.isArray(bookData)) {
        settotalVisitor(bookData.length);
         
      } else {
          console.error('Expected an array of Categories');
      }
  } catch (error) {
      console.error('Error fetching books:', error);
  }
};

const fetchUsers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api_users.php`);
        console.log(response.data);
        setUsers(response.data);
        settotalUsers(response.data.length);
    } catch (error) {
        console.error("Error fetching books:", error);
    }
};
  const books = [
    { id: 1, title: 'Book 1', author: 'Author 1', sales: 100 },
    { id: 2, title: 'Book 2', author: 'Author 2', sales: 150 },
    { id: 3, title: 'Book 3', author: 'Author 3', sales: 200 },
    // Add more books here
  ];

  const totalSales = books.reduce((acc, book) => acc + book.sales, 0);

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-3 mt-8 gap-4">
        {/* Total Visitors */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-2">
          <FaTable className="text-4xl text-orange-300" />
          <div>
            <p className="text-2xl font-semibold">{totalVisitors}</p>
            <p className="text-sm text-gray-500">Total Categories</p>
          </div>
        </div>

        {/* Total Users */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-2">
          <FaUser className="text-4xl text-green-400	" />
          <div>
            <p className="text-2xl font-semibold">{totalUsers}</p>
            <p className="text-sm text-gray-500">Total Users</p>
          </div>
        </div>

        {/* Total Books */}
        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center space-x-2">
          <FaBook className="text-4xl " />
          <div>
            <p className="text-2xl font-semibold">{totalBooks}</p>
            <p className="text-sm text-gray-500">Total Books</p>
          </div>
        </div>
      </div>
      <div className="grid mt-8 grid-cols-2 gap-2">
        <div className="register-user">
          <h1 className="text-3xl font-bold mb-4">User Information</h1>
          <UserTable users={users} />
        </div>
        <div className="book-sales">
          <h1 className="text-3xl font-bold mb-4">Total Sales of Books</h1>
          <div className="overflow-x-auto h-400">
            <table className="table-auto w-full border-collapse border">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border px-4 py-2">Book Title</th>
                  <th className="border px-4 py-2">Author</th>
                  <th className="border px-4 py-2">Number of Sales</th>
                </tr>
              </thead>
              <tbody>
                {books.map(book => (
                  <tr key={book.id}>
                    <td className="border px-4 py-2">{book.title}</td>
                    <td className="border px-4 py-2">{book.author}</td>
                    <td className="border px-4 py-2">${book.sales}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <td className="border px-4 py-2" colSpan="2">Total Sales</td>
                  <td className="border px-4 py-2" >${totalSales}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserTable = ({ users }) => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-4 py-2">User Name</th>
            <th className="border px-4 py-2">Email</th>
            <th className="border px-4 py-2">Contact Number</th>
            <th className="border px-4 py-2">Address</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td className="border px-4 py-2">{user.username}</td>
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">{user.contact}</td>
              <td className="border px-4 py-2">{user.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;
