import React, { useState } from "react";
import AddBook from "./AddBook";
import Addcat from "./AddCategory";
import Library from "./LibrayData";
import Dashboard from "./Dashboard";
import HomeBookList from "./HomeListBooks";
import AddBundle from "./AddBundle";
import AddSubscription from "./AddSubs";
import AddDonation from "./AddDonations";
import AddVol from "./AddVol";
import Addindex from "./AddIndex";
import UploadPDF from "./AddPDF";
import Order from "./OrderView";
const Admin = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
    <div className="w-[100%] flex m-auto">
      <div
        style={{
          background:
            "background: linear-gradient(180deg, #F2F9FF 0%, rgba(255, 255, 255, 0.00) 100%);",
          borderRadius: "25px", // to make the tabs pill-shaped
        }}
        className="w-[20%]"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            onClick={() => handleTabClick(1)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 1 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 1 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left  justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-l font-lato font-normal">
              Dashboard
            </p>
          </div>
          <div
            onClick={() => handleTabClick(2)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 2 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 2 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Book
            </p>
          </div>
          <div
            onClick={() => handleTabClick(13)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 13 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 13 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Upload PDF
            </p>
          </div>
          <div
            onClick={() => handleTabClick(3)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 3 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 3 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Category
            </p>
          </div>
          <div
            onClick={() => handleTabClick(4)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 4 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 4 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Homepage Libray Data
            </p>
          </div>
          <div
            onClick={() => handleTabClick(5)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 5 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 5 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Homepage Book List
            </p>
          </div>
          <div
            onClick={() => handleTabClick(6)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 6 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 6 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Bundles
            </p>
          </div>
          <div
            onClick={() => handleTabClick(7)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 7 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 7 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add subscriptions
            </p>
          </div>
          <div
            onClick={() => handleTabClick(8)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 8 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 8 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Donations
            </p>
          </div>
          <div
            onClick={() => handleTabClick(9)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 9 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 9 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Volume
            </p>
          </div>
          <div
            onClick={() => handleTabClick(12)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 12 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 12 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Add Indexes
            </p>
          </div>
          <div
            onClick={() => handleTabClick(15)}
            style={{
              padding: "0px",
              cursor: "pointer",
              borderBottomRightRadius: "25px", // adjust border radius
              borderTopRightRadius: "25px", // adjust border radius
              background: activeTab === 15 ? "#fde5c3c4" : "transparent",
              boxShadow: activeTab === 15 ?'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px': '',
            }}
          >
            <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
              Orders
            </p>
          </div>
        </div>
      </div>

      <div className="profile-right w-[75%] mt-6 m-auto">
        {activeTab === 1 && <><Dashboard/></>}
        {activeTab === 2 && <><AddBook/></>}
        {activeTab === 3 && <><Addcat/></>}
        {activeTab === 4 && <><Library/></>}
        {activeTab === 5 && <><HomeBookList/></>}
        {activeTab === 6 && <><AddBundle/></>}
        {activeTab === 7 && <><AddSubscription/></>}
        {activeTab === 8 && <><AddDonation/></>}
        {activeTab === 9 && <><AddVol/></>}
        {activeTab === 12 && <><Addindex/></>}
        {activeTab === 13 && <><UploadPDF/></>}
        {activeTab === 15 && <><Order/></>}



      </div>
      </div>
    </>
  );
};

export default Admin;
