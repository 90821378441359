import React, { useState, useContext, useEffect } from "react";
import { FaBookReader } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";
import { useNavigate} from "react-router-dom";
import htmlToPlainText from "../htmltotext";

const MySubs = () => {
  const history = useNavigate();
  const { getUserData } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("purchased");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  
  const [purchasedBooks,setPurchaseBook ]= useState([ ]);
 
  useEffect(() => {
   const  userdata = getUserData();
    GetOrder(userdata);
  }, [getUserData]);

  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_mycollection.php?user_id=${orderData.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const filteredCollection = response.data.my_collection.filter(item => item.id !== 0);
    
      setPurchaseBook(filteredCollection);
      // setPurchaseBook(response.data.my_collection);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const  userdata = getUserData();
      const response = await axios.get(`${BASE_URL}/api_mycollection.php?user_id=${userdata.id}&subscription_id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setSubscriptionDetails(response.data); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const openModal =(subscription) => {
    setSelectedSubscription(subscription);
    setModalOpen(true);
    fetchOrderDetails(subscription.id); // Fetch the order details
    // setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSubscriptionDetails(null); // Clear order details when closing the modal
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };
  return (
    <>
      <div className="flex px-4 justify-between mb-4">
        <h2 className="text-2xl font-bold">{t('my_subscriptions')}</h2>
      </div>

      <div className="mb-8 px-4">
        <div className="flex">
          <button
            className={`mr-4 focus:outline-none ${activeTab === "purchased" ? "text-blue-600 text-xl border-b-2 border-blue-600 " : "text-l text-gray-500"}`}
            onClick={() => handleTabClick("purchased")}
          >
           {t('subscriptions')}
          </button>
        </div>
      </div>

      <div className="grid px-4 grid-cols-1 gap-8">
        {/* Purchased Books */}
        {activeTab === "purchased" && (
          <div>
            <h3 className="text-xl font-semibold mb-4">{t('Psubscriptions')}</h3>
            <div className="overflow-x-auto max-h-[400px]">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('name')}</th>
                  {/* <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('no_books')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('category')}</th> */}
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
  {purchasedBooks && purchasedBooks.length > 0 ? (
    purchasedBooks.map((subscription) => (
      <tr key={subscription.id} className="border-b border-black-800">
        <td className="py-2 px-4">{subscription.title}</td>
        {/* <td className="py-2 px-4">{subscription.nobooks}</td>
        <td className="py-2 px-4">{subscription.category}</td> */}
        <td className="py-2 px-4 flex">
          <button
            className="flex items-center bg-green-500 text-white px-3 py-1 rounded"
            onClick={() => openModal(subscription)}
          >
            <FaBookReader className="mr-1" />
            {t('read_books')}
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="5" className="text-center py-4">
        No Activity Found
      </td>
    </tr>
  )}
</tbody>

            </table>
            </div>
          </div>
        )}

        {/* Modal for displaying books */}
        {modalOpen && selectedSubscription && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg w-3/4 max-w-3xl">
              <h3 className="text-xl font-semibold mb-4">{selectedSubscription.title} Subscription </h3>
              <ul>
              {subscriptionDetails ? (
              <div>
                {/* <p>Order Number: {orderDetails.order.id}</p>
                <p>Items: </p> */}
                <ul>
            {subscriptionDetails.books && subscriptionDetails.books.length > 0 ? ( // Check if books exists and has items
              subscriptionDetails.books.map((book,index) => (
                <li key={index} className="py-2 px-4 border-b border-gray-300 flex justify-between items-center">
                    <span>{htmlToPlainText(book.title)}</span>
                    <button className="bg-blue-500 text-white px-3 py-1 rounded" onClick={() =>{
                      history(`/pdfViewer/${book.id}/${htmlToPlainText(book.title)}`);
                    }}>{t('read_books')}</button>
                  </li>
                // <li key={book.id}>{book.message} - ${book.price}</li>
              ))
            ) : (
              <li>No items found...</li> // Fallback if no books are found
            )}
          </ul>
           
               
              </div>
            ) : (
              <p>Loading order details...</p>
            )}
                {/* {subscriptionDetails.books.map((book, index) => (
                  <li key={index} className="py-2 px-4 border-b border-gray-300 flex justify-between items-center">
                    <span>{book}</span>
                    <button className="bg-blue-500 text-white px-3 py-1 rounded">{t('read_books')}</button>
                  </li>
                ))} */}
              </ul>
              <button className="mt-4 bg-gray-300 text-gray-800 px-4 py-2 rounded" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MySubs;
