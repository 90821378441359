import React, { useRef, useState, useEffect ,useContext} from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import { Link } from "react-router-dom";
import BASE_URL from './constant';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next'; 
import htmlToPlainText from './htmltotext';
import { AuthContext } from './context/AuthContext';

const MyBooks = () => {
    const { getUserData } = useContext(AuthContext);
    const swiperRef = useRef();
    const [books, setBooks] = useState([]);
    const [topics, setTopics] = useState([]);
    const [slideData, setSlideData] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);

    // useEffect(() => {
    //     fetchBooks(selectedTopic);
    // }, [selectedTopic]);

    useEffect(() => {
        const userdata = getUserData();
        fetchBooks(userdata);
    }, []);

    const fetchBooks = async (userdata) => {
        try {
            let url = `${BASE_URL}/api_book.php/favoritebooks?user_id=${userdata.id}`;
            
            const response = await axios.get(url);
            const bookData = response.data;
            if (Array.isArray(bookData)) {
                sliderData1(bookData);
                // extractTopics(bookData);
            } else {
                console.error('Expected an array of books');
            }
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    };

    // const handleTopicChange = (topic) => {
    //     setSelectedTopic(topic);
    // };

    // const extractTopics = (books) => {
    //     const allTopics = books.reduce((acc, book) => {
    //         if (book.topics) {
    //             book.topics.forEach(topic => {
    //                 if (!acc.includes(topic)) {
    //                     acc.push(topic);
    //                 }
    //             });
    //         }
    //         return acc;
    //     }, []);
    //     setTopics(allTopics);
    // };

    const sliderData1 = (books) => {
        const slideData = books.reduce((acc, book) => {
            acc.push({ id: book.id, image: '../images/download.png', title: book.book_name,book_id:book.book_id }); // Added 'id' for the Link
            return acc;
        }, []);
        setSlideData(slideData);
    };

    useEffect(() => {
        const fitText = () => {
            const textElements = document.querySelectorAll('.slide-text');
            textElements.forEach(textElement => {
                const parentElement = textElement.parentElement;
                const text = textElement.innerText;
                const textLength = text.length;

                // Adjust font size based on text length
                let fontSize;
                if (textLength < 10) {
                    fontSize = 35; // Adjust as needed
                } else if (textLength < 20) {
                    fontSize = 27; // Adjust as needed
                }
                    else if (textLength < 30) {
                        fontSize = 19; // Adjust as needed
                } else if (textLength < 55) {
                    fontSize = 16; // Adjust as needed
            } else {
                    fontSize = 10; // Adjust as needed
                }

                textElement.style.fontSize = `${fontSize}px`;
            });
        };

        // Call fitText initially and on window resize
        fitText();
        window.addEventListener('resize', fitText);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', fitText);
        };
    }, []);
    const {t}=useTranslation();

    return (
        <>
            <div className='relative px-8 my-5'>
                <div className='title-fil w-full bg-[#0000007a] p-4 mb-2  items-center'>
                    <h1 className="text-3xl text-center text-red-900 text-white  my-2 mr-4 flex-shrink-0">
                    {t('my_books')}</h1>
                </div>
                
                <div >
                <div className="mslid" style={{ margin: "0px 12px" }}>
            {slideData.length > 0 ? (
                <Swiper slidesPerView={5} modules={[Navigation]} className="myhomeSwiperslider">
                    {slideData.map((slide, index) => (
                        <SwiperSlide key={index} className='myhomeSwiperslider'>
                            <Link to={`/pages/Product/${slide.book_id}`}>
                                <img src={slide.image} alt="Book" className="w-full h-[250px] transition-transform duration-300 transform hover:scale-110" />
                                <div className="absolute top-2 left-6px h-[96%] flex items-center justify-center">
                                    <span className="inline-block vrttext text-[#daa520] slide-text tilt-neon">{htmlToPlainText(
                                    slide.title)}</span>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <p style={{ color: 'white', fontWeight: 'bold' }}>{t('no_f_b_s')}</p>
               
            )}
        </div>
                    <img src='../images/mybook3.png'/>
                    <button className='swiper-button-prev video-bnt'
                        style={{ position: "absolute", left: "2px", color: "white", fontWeight: "800", top: "56%", width: "36px", height: "35.997px" }} onClick={() => swiperRef.current?.slidePrev()}></button>
                    <button className='swiper-button-next video-bnt'
                        style={{ position: "absolute", right: "-2px", color: "white", fontWeight: "800", top: "56%", width: "36px", height: "35.997px" }} onClick={() => swiperRef.current?.slideNext()}></button>
                </div>
            </div>
        </>
    )
}

export default MyBooks;
