import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./Stripe.css";
import axios from "axios";
import {useLocation} from 'react-router-dom';
// import { useParams } from "react-router-dom";


const stripePromise = loadStripe("pk_test_qblFNYngBkEdjEZ16jxxoWSM");

const Stripepayments = () => {
  // const { state } = this.props.location
  // const { state } = useLocation(); const {  } = state;
  const location = useLocation();
  const product = location.state.product;
  const type = location.state.type;
  const amount = location.state.amount;
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    console.log(amount);
    if (amount) {
      axios.post("https://api.stripe.com/v1/payment_intents",
        new URLSearchParams({
          amount: (parseInt(amount, 10) * 100).toString(),
          currency: 'usd'
        }), {
        headers: {
          'Authorization': 'Bearer sk_test_26PHem9AhJZvU623DfE1x4sd',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((response) => {
        console.log(response.data);
        setClientSecret(response.data.client_secret);
      })
      .catch((error) => {
        console.error("Error creating PaymentIntent:", error);
      });
    }
  }, [amount]);

  const appearance = {
    theme: 'stripe',
  };
  
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm product={product}
          type={type}
          amount={amount}  />
        </Elements>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Stripepayments;
