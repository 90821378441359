import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, onSnapshot, orderBy } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDr7ojQJQS7JEPtbL2B3Q1HAX3qFmo8t2k",
  authDomain: "bookstore-d53d1.firebaseapp.com",
  projectId: "bookstore-d53d1",
  storageBucket: "bookstore-d53d1.appspot.com",
  messagingSenderId: "1006245372488",
  appId: "1:1006245372488:web:2e705cfe6dc821cadf2e7d",
  measurementId: "G-THMJDKMC4N"
};

const app = initializeApp(firebaseConfig);

 const auth = getAuth(app);
 const firestore = getFirestore(app);
 const analytics = getAnalytics(app);
 const provider = new GoogleAuthProvider();
 const storage = getStorage(app);

export { auth, firestore, provider,analytics,storage };