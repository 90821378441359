import React, { useState, useEffect,useContext } from 'react';
import Search from "./Home-search";
import Books from "./Home-Books";
import ListBook from "./Home-list";
import MyBooks from "./Home-my-books";
// import FavBooks from "./FavBook";
// import Door from "./Door";
import { AuthContext } from './context/AuthContext';
import HomeDonation from "./DonationBox";


const Home = () => {
    const { currentUser,getUserData } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [hideBanner, setHideBanner] = useState(false);
    const [showHomepage, setShowHomepage] = useState(false);

    // Simulate lazy loading effect with useEffect
    useEffect(() => {
        if (isOpen && !showHomepage) {
            setTimeout(() => {
                setShowHomepage(true);
            }, 500); // Delay showing homepage content after 500ms (simulating lazy loading)
        }
    }, [isOpen, showHomepage]);

    const toggleDoor = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setTimeout(() => {
                setHideBanner(true);
            }, 3000); // Hide banner after 3 seconds
        } else {
            setShowHomepage(false); // Hide homepage content when closing the door
        }
    };

    return (
        <>
            {!hideBanner && (
                <div id="main-banner" className='main-bg' style={{ position: "absolute", width: "100%", height: "100%", zIndex: "1999" }}>
                    <div id="banner" style={{
                        backgroundSize: 'contain',
                        height: '860px',
                        width: "100%",
                        left: "0%",
                        top: "-100px",
                        border: 'none',
                        textAlign: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                    }}>
                        <div className="doorHoverLeft" style={{
                            position: 'absolute',
                            left: isOpen ? '-50%' : '0%',
                            top: '0',
                            bottom: '0',
                            width: '50%',
                            transition: '3s ease-in-out',
                            boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.40)',
                            background: 'url("../images/lbg.jpg")'
                            // Add other browser-specific gradient properties here
                        }}>
                        <span class="doorText TLeft">התורה </span>
                            <img src='../images/door1.png' style={{ height: "960px", width: "100%", padding:"25px 0px 56px 35px"  }} alt="Door 1" />
                        </div>

                        <div className="doorHoverRight" style={{
                            position: 'absolute',
                            right: isOpen ? '-50%' : '0%',
                            top: '0',
                            bottom: '0',
                            width: '50%',
                            transition: '3s ease-in-out',
                            boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.40)',
                            background: 'url("../images/lbg.jpg")'
                            // Add other browser-specific gradient properties here
                        }}>
                        <span class="doorText TRight">היכל</span>
                            <img src='../images/door2.png' style={{ height: "960px", width: "100%", padding:"30px 35px 56px 0px" }} alt="Door 2" />
                        </div>

                        {!isOpen && (
                            <div className='door-button'>
                            <button className='bg-[#A1662F] hover:bg-[#A1662F] text-white font-bold py-3 px-4 rounded mt-4' onClick={toggleDoor}>כניסה</button>
                        </div>
                        )}
                    </div>
                </div>
            )}

            {showHomepage && (
                <div className='homepage fade-in'>
                    <div className="p-10" style={{ background: 'url("../images/lbg.jpg")', backgroundSize: "cover" }}>
                        <div className="w-full sm:w-[100%] flex m-auto">
                            <div className="w-[25%]">
                            {currentUser ? (
                <MyBooks />
            ) : (
                <img src="../images/locked.png" className='m-auto mt-10'/>
            )}
               
                            
                            </div>
                            <div className="w-[50%]">
                                <Search />
                            </div>
                            <div className="w-[25%]">
                               <HomeDonation/>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-[50%]" style={{ zIndex: "0" }}>
                            
                            <Books />
                        </div>
                        <div className="w-[50%]">
                            <ListBook />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Home;
