import React, { useState,  useContext, useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { FaBookReader } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";
import { useNavigate} from "react-router-dom";
import htmlToPlainText from "../htmltotext";

const MyBooks = () => {
  const [activeTab, setActiveTab] = useState("purchased");
  const history = useNavigate();
  const { getUserData } = useContext(AuthContext);
  // Sample data for purchased and rented books
  const [purchasedBooks,setPurchaseBook] = useState([
    // { id: 1, name: "Book 1", author: "Author 1", category: "Fiction" },
    // { id: 2, name: "Book 2", author: "Author 2", category: "Non-fiction" },
    // // Add more purchased books as needed
  ]);

  useEffect(() => {
    const  userdata = getUserData();
    GetMyBooks(userdata);
   }, [getUserData]);
 
   const GetMyBooks = async (orderData) => {
     try {
       const response = await axios.get(`${BASE_URL}/api_mycollection.php?user_id=${orderData.id}&collection=mycollection`, {
         headers: {
           'Content-Type': 'application/json',
         },
       });
      //  const filteredCollection = response.data.my_collection.filter(item => item.id !== 0);
     
      //  setPurchaseBook(filteredCollection);
      setPurchaseBook(response.data);
     } catch (error) {
       console.error('Error fetching orders:', error);
     }
   };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        {/* <h2 className="text-2xl font-bold">{t('my_books')}</h2> */}
      </div>

      <div className="mb-8">
        <div className="flex">
          {/* <button
            className={`mr-4 focus:outline-none ${activeTab === "purchased" ? "text-blue-600 text-xl border-b-2 border-blue-600 " : "text-l text-gray-500"}`}
            onClick={() => handleTabClick("purchased")}
          >
         {t('purchased_books')}
          </button> */}
         
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8">
        {/* Purchased Books */}
        {activeTab === "purchased" && (
          <div>
            <h3 className="text-xl font-semibold mb-4"> {t('purchased_books')}</h3>
            <div className="overflow-x-auto max-h-[400px]">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('name')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('author')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('category')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
              {purchasedBooks ? (
              <>
                {/* <p>Order Number: {orderDetails.order.id}</p>
                <p>Items: </p> */}
                
            {purchasedBooks.books && purchasedBooks.books.length > 0 ? ( // Check if books exists and has items
              purchasedBooks.books.map((book,index) => (
                <tr key={book.id} className="border-b border-black-800">
                    <td className="py-2 px-4">{htmlToPlainText(book.title)}</td>
                    <td className="py-2 px-4">{htmlToPlainText(book.auther)}</td>
                    <td className="py-2 px-4">{book.category}</td>
                    <td className="py-2 px-4 flex">
                     
                      <button className="flex ml-2 mr-2  items-center bg-green-500 text-white px-3 py-1 rounded"onClick={() =>{
                      history(`/pdfViewer/${book.id}/${htmlToPlainText(book.title)}`);
                    }}>
                        <FaBookReader  className="mr-1"  />
                         {t('read_books')}
                      </button>
                      {/* <button className="flex ml-2 mr-2 items-center bg-blue-500 text-white px-3 py-1 rounded">
                        <FiDownload className="mr-1" />
                         {t('download')}
                      </button> */}
                    </td>
                  </tr>
                // <li key={book.id}>{book.message} - ${book.price}</li>
              ))
            ) : (
              <li>No items found...</li> // Fallback if no books are found
            )}
          
           
               
              </>
            ) : (
              <p>Loading order details...</p>
            )}
               
              </tbody>
            </table>
            </div>
          </div>
        )}

       
      </div>
    </>
  );
};

export default MyBooks;
