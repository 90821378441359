import React, { useState,useEffect,useContext } from "react";
import { FaUser, FaPhone, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import BASE_URL from "../constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next'; 

const AboutUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconformPassword] = useState(false);
  const { saveUserData, getUserData } = useContext(AuthContext);
  // const [userData, setUser] = useState([]);
  const [name, setName] = useState("");
  // const [gender, setGender] = useState("");
  // const [profile, setprofile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [address, setAddress] = useState("");
  // const [newProfileImage, setNewProfileImage] = useState(null);
  const [uid, SetUid] = useState("");
  useEffect(() => {
    const userData = getUserData();
    console.log("userData.phone:", userData.contact); // Log userData.phone
    console.log("userData.id", userData.id);
    SetUid(userData.id);
    setAddress(userData.address);
    setName(userData.username);
    setEmail(userData.email);
    if (userData.contact) {
      // Remove leading "+" sign if present in phone number
      const formattedPhoneNumber = userData.contact.startsWith('+') ? userData.contact.slice(1) : userData.contact;
      setMobileNumber(formattedPhoneNumber);
      console.log(userData.contact);
    }// Check this line
  
   
  }, [getUserData]);

  // State variables to store user data


  // Function to handle the update profile button click
  const handleUpdateProfile = () => {
    // Save or process the user data as needed
    const userDatas = {
      username: name,
      contact: mobileNumber,
      address: address
    };
  
    const config = {
      method: 'put',
      url: `${BASE_URL}/api_users.php?id=${uid}`,
      data: userDatas,
    };
  
    axios(config)
      .then((response) => {
        saveUserData(userDatas);
        toast.success("User Updated Successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while updating the profile. Please try again.");
      });
  };
  
  const {t}=useTranslation();

  return (
    <>
      <div className="mt-4 space-y-4">
      <ToastContainer/>
      <h1 className="text-4xl font-bold text-center mt-8 mb-4"> {t('about_me')}</h1>
        {/* Full Name input with icon */}
        <div className="relative">
          <FaUser className="absolute top-3 left-3 text-gray-400" />
          <input
            type="text"
            placeholder={t('full_name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="pl-10 text-left w-full h-10 px-4 rounded-md border border-gray-300"
          />
        </div>
        {/* Contact Number input with icon */}
        <div className="relative">
          <FaPhone className="absolute top-3 left-3 text-gray-400" />
          <input
            type="text"
            placeholder={t('contactN')}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="pl-10 text-left w-full h-10 px-4 rounded-md border border-gray-300"
          />
        </div>
        {/* Email input with icon */}
        <div className="relative">
          <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
          <input
            type="email"
            // placeholder="Email"
            className="pl-10 text-left w-full h-10 px-4 rounded-md border border-gray-300"
            readOnly
            value={email}
            // onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
          />
        </div>
        {/* Password input with icon */}
        {/* <div className="relative">
          <FaLock className="absolute top-3 left-3 text-gray-400" />
          <input
            type={showPassword ? "text" : "password"}
            placeholder={t('password')}
            className="pl-10 text-left w-full h-10 px-4 rounded-md border border-gray-300"
          /> */}
          {/* Show password toggle button */}
          {/* <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute top-3 right-3 focus:outline-none"
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div> */}
        
        {/* Address input with icon */}
        <div className="relative">
          <FaMapMarkerAlt className="absolute top-3 left-3 text-gray-400" />
          <input
            type="text"
            placeholder={t('Address')}
            className="pl-10 text-left w-full h-10 px-4 rounded-md border border-gray-300"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
      </div>
      {/* Save Button */}
      <button className="bg-green-500 text-white px-8 py-2 rounded-md mt-4" onClick={handleUpdateProfile}>
      {t('save')}
      </button>
    </>
  );
};

export default AboutUser;
