import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import {
  FaShoppingCart,
  FaBookReader,
  FaHeart,
  FaHeartBroken,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../constant";
import Slider from "react-slick";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AuthContext } from "../context/AuthContext";

import {
  RiCheckboxBlankCircleFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";

const Product = () => {
  const swiperRefswe = useRef(null);
  const { id } = useParams();
  const [showRentForm, setShowRentForm] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");
  const serviceId = parseInt(id, 10);
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const { cart, addToCart, removeFromCart, currentUser, getUserData } =
    useContext(AuthContext);
  // const userData = getUserData();
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the book is favorited
  const [favoriteid, setFavorite] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [size, setSize] = useState([]);
  const [price, setPrice] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api_book.php/books/${serviceId}`)
      .then((res) => {
        setProduct(res.data);
        setMaterials(
          res.data.material?.map((item) => ({
            id: item.index,
            name: item,
            selected: false,
          })) || []
        );
        setSize(
          res.data.size?.map((item) => ({
            id: item.index,
            name: item,
            selected: false,
          })) || []
        );
        checkIfFavorite();
      })
      .catch((err) => console.log(err));
  }, [serviceId]);
  useEffect(() => {
    if (!serviceId) return; // Ensure serviceId is defined

    axios
      .get(`${BASE_URL}/api_volumes.php?book_id=${serviceId}`)
      .then((res) => {
        const slideData = res.data.map((book) => ({
          ...book,
          quantity: 0,
          selected: false,
        }));

        // Assuming product and its properties are defined
        const updatedItems = [
          {
            id: "default-id",
            title: product.title,
            price: product.price,
            quantity: 0,
            //material: product.material[0],
            selected: false,
          },
          ...slideData,
        ];

        setItems(updatedItems);
      })
      .catch((err) => {
        setPrice(product.price);
        console.error("Error fetching data:", err);
      });
  }, [serviceId, product]); // Ensure product is also included if used

  const [modalOpen, setModalOpen] = useState(false);
  const [passwordmodalOpen, setpasswordModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openpassModal = () => {
    setpasswordModalOpen(true);
  };

  const closepassModal = () => {
    setpasswordModalOpen(false);
  };
  // Function to check if the current book is favorited by the user
  const checkIfFavorite = () => {
    console.log(currentUser.id);
    const userData = getUserData();

    axios
      .get(
        `${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}&book_id=${id}`
      )
      .then((res) => {
        console.log(res.data);

        setIsFavorite(res.data.length > 0);
        setFavorite(res.data[0].id);
        console.log(res.data[0].id); // Update isFavorite state based on API response
      })
      .catch((err) => console.log(err));
  };

  const handleRentClick = () => {
    setShowRentForm(true);
  };

  const handleToggleFavorite = async () => {
    const userData = getUserData();
    if (isFavorite) {
      // Remove from favorites logic
      console.log(favoriteid);
      try {
        const response = await axios.delete(
          `${BASE_URL}/api_book.php/favoritebooks/${favoriteid}`
        );

        if (response.status === 200) {
          alert("Book removed from favorites!");
          setIsFavorite(false); // Update UI to reflect removal
        } else {
          alert("Failed to remove book from favorites");
        }
      } catch (error) {
        alert("Failed to remove book from favorites. Please try again later.");
        console.error("Error removing book from favorites:", error);
      }
    } else {
      // Add to favorites logic
      try {
        const formData = new FormData();

        formData.append("user_id", userData.id);
        formData.append("book_id", id);

        // Configure the Axios request
        const config = {
          method: "post",
          url: `${BASE_URL}/api_book.php/favoritebooks`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        // Make the Axios request
        axios(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            //setNewProfileImage(null);
            alert("Book added to favorites!");
            setIsFavorite(true); // Update UI to reflect addition
          })
          .catch((error) => {
            alert("Failed to add book to favorites");
            console.log(error);
          });
      } catch (error) {
        alert("Failed to add book to favorites. Please try again later.");
        console.error("Error adding book to favorites:", error);
      }
    }
  };
  const purchase = async (e, pro) => {
    e.preventDefault();

    if (currentUser) {
      if (items.length > 0) {
        const data = {
          items_id: items
            .filter((item) => item.selected) // Filter out unselected items
            .map((item) => item.id),
          items_quantity: items
            .filter((item) => item.selected) // Filter out unselected items
            .map((item) => item.quantity),
          product: pro,
          quantity: 0,
          price: price,
        };
        addToCart(data);
      } else {
        const data = {
          items_id: null,
          items_quantity: null,
          product: pro,
          quantity: quantity,
          price: price,
        };
        addToCart(data);
      }
      // addToCart(pro);
    } else {
      toast.error(`Your need to login first`);
    } //  navigate('/pages/Stripe',{state:{
  };
  useEffect(() => {
    console.log(BASE_URL);
    console.log(id);
    axios
      .get(`${BASE_URL}/api_book.php/books/${serviceId}`)
      .then((res) => {
        console.log(res.data);
        setProduct(res.data); // Assuming the response has a services array
      })
      .catch((err) => console.log(err));
  }, [serviceId]);

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // vol
  const [items, setItems] = useState([]);
  const handleCheckboxChange = (itemId) => {
    setItems(
      items.map((item) => {
        if (item.id === itemId) {
          const updatedSelected = !item.selected;
          handleitemQuantityChange(itemId, updatedSelected ? 1 : 0);
          return {
            ...item,
            selected: updatedSelected,
            quantity: updatedSelected ? 1 : 0,
          };
        }
        return item;
      })
    );
  };

  const handleitemQuantityChange = (itemId, newQuantity) => {
    console.log(itemId, newQuantity);
    const updatedItems = items.map((item) =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    );
    const newPrice = updatedItems.reduce((total, item) => {
      return total + item.quantity * parseFloat(item.price);
    }, 0.0);
    console.log(newPrice.toFixed(2));
    setItems(updatedItems);
    setPrice(newPrice.toFixed(2));
  };

  // const handleCheckboxChange = (itemId) => {
  //   setItems(items.map(item => {
  //     if (item.id === itemId) {
  //       const updatedSelected = !item.selected;
  //       handleitemQuantityChange(itemId,updatedSelected?"1":0,updatedSelected?"+":"-");
  //     //  setPrice(prevPrice => parseInt(prevPrice) + (updatedSelected ?parseInt(item.price) : -parseInt(item.price)));
  //       return { ...item, selected: !item.selected };
  //     }
  //     return item;
  //   }));
  // };

  // const handleitemQuantityChange = (itemId, newQuantity,sign) => {
  //   console.log(itemId, newQuantity,sign);
  //   const updatedItems = items.map(item =>
  //     item.id === itemId ? { ...item, quantity: newQuantity } : item
  //   );

  //     const selectedItem = updatedItems.find(item => item.id === itemId);

  //   // Check if the item is selected and quantity > 0
  //   if (selectedItem.selected && newQuantity > 0) {
  //     const priceChange = (newQuantity) * parseFloat(selectedItem.price);
  //   if(sign=="+"){
  //     const updatedPrice = parseFloat(price) + priceChange;
  //     console.log(updatedPrice);

  //     setPrice(updatedPrice);}else{
  //       const updatedPrice = parseFloat(price) - priceChange;
  //     console.log(updatedPrice);

  //     setPrice(updatedPrice);
  //     }
  //   }

  // setItems(updatedItems);
  // };
  const handleMaterialCheckboxChange = (itemname) => {
    setMaterials(
      materials.map((item) => ({
        ...item,
        selected: item.name === itemname ? !item.selected : item.selected,
      }))
    );
  };
  const history = useNavigate();
  const [code, setCode] = useState('');
  const handleChange = (index, value) => {
    // Ensure the value is a single digit (0-9)
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    }
  };
  function htmlToPlainText(html) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
  }
  const checkCode = () => {
    const enteredCode = code.join('');
    // Example condition: Check if code is valid (e.g., length is 4 and matches a specific code)
    if (enteredCode.length === 4 && enteredCode === "3167") {
      // Navigate to the PDF viewer page
      history(`/pdfViewer/${product.id}/${htmlToPlainText(product.title)}`);
    } else {
      // Show error alert if code is invalid
      alert("Invalid code. Please enter a valid 4-digit code.");
    }
  };

  const handleSizeCheckboxChange = (itemname) => {
    setSize(
      size.map((item) => ({
        ...item,
        selected: item.name === itemname, // Set selected to true for the clicked item, false for others
      }))
    );
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Check if product or product.description is undefined or null
  if (!product || !product.description) {
    return null; // or render an appropriate message or placeholder
  }
  const handleQuantityChange = (quantity, newPrice) => {
    setQuantity(quantity);
    setPrice(newPrice.toFixed(2));
  };

  // Conditional rendering of BookQuantityPicker based on items length
  const renderBookQuantityPicker = () => {
    if (items.length > 0) {
      return null;
    }
    return (
      <>
        <div className="mb-2">
          <span className="text-lg font-bold mt-2  mr-2">{t("quantity")}</span>
          <BookQuantityPicker
            product={product}
            onChange={handleQuantityChange}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container mx-auto px-4 py-8 lg:flex w-full">
        <ToastContainer />
        <div className="pr-4 product-pic">
          {product.images ? (
            <Swiper
              modules={[Navigation]}
              navigation={true}
              className="mySwiper"
              onBeforeInit={(swiper) => {
                swiperRefswe.current = swiper;
              }}
            >
              {product.images.map((image, imageIndex) => (
                <SwiperSlide key={imageIndex}>
                  <img
                    src={image}
                    className="service-img"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "24px",
                    }}
                    alt={`Image ${imageIndex + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>No images available</p>
          )}
        </div>
        <div className="product-info">
          <div className="flex items-center p-4">
            <h2 class="text-2xl w-[70%] font-bold text-black mb-2">
            <Markup content={product.title}/>
              
            </h2>

            <button
              className={`bg-${
                isFavorite ? "green-500" : "[#fde5c3]"
              }  hover:bg-${
                isFavorite ? "[#fde5c3]" : "green-600"
              } text-black px-4 py-2 rounded-lg flex w-[30%]  items-center`}
              onClick={handleToggleFavorite}
            >
              {isFavorite ? (
                <FaHeartBroken className="mr-2" />
              ) : (
                <FaHeart className="mr-2" />
              )}
              {isFavorite ? <>{t("remove_fav")}</> : <>{t("add_fav")}</>}
            </button>
          </div>

          <div className=" justify-between mt-0 ml-0 items-center p-4 ">
            <div className="flex items-center">
              <span className="text-lg font-bold mr-2">
                {t("description")}:
              </span>
            </div>
            <div className="flex flex-wrap">
              {showFullDescription ? (
                <p>
                  <Markup  content={product.description} /> 
                  {/* {product.description}{" "} */}
                  {product.description.length > 200 && (
                    <button
                      className="bg-gray-200 text-[12px] p-1"
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? "Show less" : "Read more"}
                    </button>
                  )}
                </p>
              ) : (
                <p>
                  {product.description.length > 200
                    ? <Markup  content={ product.description.slice(0, 200) + "..."} /> 
                   
                    :<Markup  content={product.description} /> 
                  }
                  {product.description.length > 200 && (
                    <button
                      className="bg-gray-200 text-[12px] p-1"
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? "Show less" : "Read more"}
                    </button>
                  )}
                </p>
              )}
            </div>
            <div className="flex items-center">
              <span className="text-lg font-bold  mt-2 mr-2">
                {t("SKU")}: {product.asin}
              </span>
            </div>
            <p>
              <span className="text-lg font-bold mr-2">
                {t("arthor_name")}:
              </span>
              <span className="text-lg font-bold mr-0">
              <Markup content={product.auther}/>
              </span>
            </p>
            <div className="flex items-center">
              <span className="text-lg font-bold mr-2">{t("material")}:</span>
            </div>
            <div className="flex flex-wrap">
              {materials.map((material, index) => (
                <>
                {/* <label
                  key={material.name}
                  className={`flex items-center mr-2 mb-2 ${
                    material.selected
                      ? "bg-[#6d3815] text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-[#6d3815] hover:text-white"
                  } rounded-md px-4 py-2 cursor-pointer`}
                >
                  <input
                    type="checkbox"
                    className="hidden"
                    checked={material.selected}
                    onChange={() => handleMaterialCheckboxChange(material.name)}
                  />
                  <span>{material.name}</span>
                </label> */}
                <span>{material.name}</span>
                </>
              ))}
            </div>

            <div className="flex items-center">
              <span className="text-lg font-bold mt-2 mr-2">{t("Size")}:</span>
            </div>
            <div className="flex flex-wrap">
              {size.map((material) => (
                <>
                {/* <label
                  key={material.name}
                  className={`flex items-center mr-2 mb-2 ${
                    material.selected
                      ? "bg-[#6d3815] text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-[#6d3815] hover:text-white"
                  } rounded-md px-4 py-2 cursor-pointer`}
                >
                  <input
                    type="checkbox"
                    className="hidden"
                    checked={material.selected}
                    onChange={() => handleSizeCheckboxChange(material.name)}
                  />
                  <span className="ml-2">{material.name}</span>
                </label> */}
                <span className="ml-2">{material.name}</span>
                </>
              ))}
            </div>
            {renderBookQuantityPicker()}

            {/* <button
        onClick={openModal}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
      >
        Size

      </button> */}

            {modalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg w-3/4">
                  <div className="flex justify-end">
                    <button
                      onClick={closeModal}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <AiOutlineClose size={24} />
                    </button>
                  </div>
                  <div className="mt-4">
                    {/* Replace with your modal content, e.g., images */}
                    <img
                      src="https://via.placeholder.com/400"
                      alt="Modal Content"
                      className="w-full h-[500px]"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <p className="text-gray-700 mb-4">{product.des}</p>
          {items.length !== 0 ? (
            <>
              <h2 className="text-lg font-bold m-2">{t("volumes")}</h2>
              <div className="overflow-x-auto mb-4">
                <table className="min-w-full bg-white border-collapse">
                  <thead>
                    <tr className="border-b">
                      <th className="text-center py-2 px-4">{t("volume")}</th>
                      <th className="text-center py-2 px-4">{t("price")}</th>
                      <th className="text-center py-2 px-4">{t("quantity")}</th>
                      <th className="text-center py-2 px-4">{t("add")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item) => (
                      <tr key={item.id} className="border-b">
                        <td className="text-left py-2 px-4">{item.title}</td>
                        <td className="text-left py-2 px-4">{item.price}</td>
                        <td className="text-center py-2 px-4">
                          <QuantityPicker
                            item={item}
                            onChange={handleitemQuantityChange}
                          />
                        </td>
                        <td className="text-center py-2 px-4">
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox  h-5 w-5 text-indigo-600"
                              checked={item.selected}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                            {/* You can add label text here if needed */}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <h2 className=""> </h2>
          )}
          <div className=" items-center space-x-4">
            <div className="md:flex sm:block">
              <div className="md:w-[49%] m-auto sm:w-full">
                {/* <Link to=
                  {`/pdfViewer/${product.id}/${product.title}`}> */}
                <button
                  onClick={openpassModal}
                  className="bg-[#6D3815] hover:bg-[#fde5c3] w-full text-white hover:text-black px-4 py-2 rounded-lg flex items-center"
                >
                  <FaBookReader className="mr-2" />
                  {t("view_book")}
                </button>
                {/* </Link> */}
                {passwordmodalOpen && (
                  <div  className="fixed inset-0  z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white w-[40%] p-8 rounded-lg shadow-lg w-3/4">
                      <div className="flex justify-end">
                        <button
                          onClick={closepassModal}
                          className="text-gray-600 hover:text-gray-800"
                        >
                          <AiOutlineClose size={24} />
                        </button>
                      </div>
                      <div className="mt-4 p-4">
                        <p className="text-xl text-center">
                          Enter password to view book
                        </p>
                        <div dir="ltr" className="flex justify-center mt-2 space-x-2">
      <input
        type="number"
        maxLength="1"
        value={code[0]}
        onChange={(e) => handleChange(0, e.target.value)}
        className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
        placeholder="0"
      />
      <input
        type="number"
        maxLength="1"
        value={code[1]}
        onChange={(e) => handleChange(1, e.target.value)}
        className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
        placeholder="0"
      />
      <input
        type="number"
        maxLength="1"
        value={code[2]}
        onChange={(e) => handleChange(2, e.target.value)}
        className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
        placeholder="0"
      />
      <input
        type="number"
        maxLength="1"
        value={code[3]}
        onChange={(e) => handleChange(3, e.target.value)}
        className="block w-[62px] text-center border-2 border-[#fde5c3] rounded-md text-lg focus:border-red-500 focus:ring-red-500"
        placeholder="0"
      />
    </div>

                        <p
                          id="helper-text-explanation"
                          class="mt-2 text-sm text-gray-500 text-center"
                        >
                          Please introduce the 4 digit code
                        </p>
                        <div className="w-full">
                          <button
                            type="button"
                            className="text-white w-[100%] bg-gradient-to-r from-cyan-400 via-cyan-500 m-auto to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                         onClick={()=>{checkCode();}}
                         >
                            View Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {showRentForm && (
              <div className="container mx-auto px-4 py-4 pt-0">
                <h2>Book viewed for:</h2>
                <form className="flex items-center space-x-4">
                  <div>
                    <label
                      htmlFor="duration"
                      className="text-gray-700 font-bold"
                    >
                      Duration: One Month
                    </label>
                    
                  </div>
                  {selectedDuration && (
                    <div>
                      <label
                        htmlFor={selectedDuration}
                        className="text-gray-700 font-bold"
                      >
                        {selectedDuration.charAt(0).toUpperCase() +
                          selectedDuration.slice(1)}{" "}
                        :
                      </label>
                      <select
                        id={selectedDuration}
                        className="border px-2 py-1 rounded-full bg-blue-200 text-blue-800"
                        defaultValue="1"
                      >
                        {[...Array(selectedDuration === "days" ? 6 : selectedDuration === "weeks" ? 4 : 12)].map(
                          (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                  <Link to=
                  {`/pdfViewer/${product.id}/${product.title}`}>
                    <button
                      className="bg-[#fde5c3] hover:bg-[#6D3815] text-white px-4 py-2 rounded-lg"
                      type="submit"
                    >
                      {t('view_book')}
                    </button>
                  </Link>
                </form>
              </div>
              
            )} */}
              </div>
              <div className="md:w-[49%] sm:w-full">
                <button
                  className="bg-[#6D3815] w-full hover:bg-[#fde5c3] hover:text-[#000] text-white px-4 py-2 rounded-lg flex items-center"
                  onClick={(e) => purchase(e, product)}
                  style={{ marginLeft: "0px" }}
                >
                  <FaShoppingCart className="mr-2" />
                  {t("buy_book")} ${price}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const QuantityPicker = ({ item, onChange }) => {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(item.quantity);
    // if(item.selected==true){
    //   setQuantity(1);
    // }
  }, [item.quantity]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(item.id, newQuantity);
    }
  };

  const increaseQuantity = () => {
    if (item.selected) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onChange(item.id, newQuantity);
    }
  };

  return (
    <div className="flex items-center">
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-l focus:outline-none"
        onClick={decreaseQuantity}
      >
        -
      </button>
      <span className="px-4">{quantity}</span>
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-r focus:outline-none"
        onClick={increaseQuantity}
      >
        +
      </button>
    </div>
  );
};
const BookQuantityPicker = ({ product, onChange }) => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange(newQuantity, newQuantity * parseFloat(product.price));
    }
  };

  const increaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onChange(newQuantity, newQuantity * parseFloat(product.price));
  };

  return (
    <div className="flex items-center">
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-l focus:outline-none"
        onClick={decreaseQuantity}
      >
        -
      </button>
      <span className="px-4">{quantity}</span>
      <button
        className="bg-[#fde5c3c4] text-gray-700 px-4 py-1 rounded-r focus:outline-none"
        onClick={increaseQuantity}
      >
        +
      </button>
    </div>
  );
};
export default Product;
