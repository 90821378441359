import React, { useState, useEffect, useRef } from "react";
import Pdfbar from "./Custom-pdf-bar";
// import React, { useState, useRef, useEffect } from 'react';
// import { Viewer, Worker } from "@react-pdf-viewer/core";
import Tesseract from "tesseract.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { CharacterMap, Viewer, Worker } from "@react-pdf-viewer/core";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
  MenuComponent,
} from "@syncfusion/ej2-react-navigations";
import {
  searchPlugin,
  NextIcon,
  PreviousIcon,
  searchFor,
} from "@react-pdf-viewer/search";
import {
  highlightPlugin,
  RenderHighlightTargetProps,
} from "@react-pdf-viewer/highlight";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { IoMdArrowDropup } from "react-icons/io";
import { MdOutlineFitScreen } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import { PiArrowBendDoubleUpRightBold } from "react-icons/pi";
import { PiArrowBendDoubleUpLeftBold } from "react-icons/pi";
import { MdLastPage } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { AiOutlineZoomOut } from "react-icons/ai";
import { AiOutlineZoomIn } from "react-icons/ai";
import html2canvas from "html2canvas";
import { IoMdCut } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { GrChapterAdd } from "react-icons/gr";
import { RiPageSeparator } from "react-icons/ri";
import { FaCirclePlus } from "react-icons/fa6";
import { SiImessage } from "react-icons/si";
import { FaKey } from "react-icons/fa";
import { FaMarker } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { VscEmptyWindow } from "react-icons/vsc";
import { FaBook } from "react-icons/fa";
import { BsCardHeading } from "react-icons/bs";
import BASE_URL from "../constant";

// Import pdfjs-dist and set the workerSrc
import { version as pdfjsVersion } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist";
import { saveAs } from "file-saver";
import pdf_URL from "../constant_pdf";
import htmlToPlainText from "../htmltotext";
import axios from "axios";
import { savePDFToIndexedDB, getPDFFromIndexedDB } from "./pdfcacher";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  PageOrganizer,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { registerLicense } from "@syncfusion/ej2-base";

// Add your Syncfusion license key here
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NDaF5cWGJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ecXRSRmBfUkR+V0A="
);
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

const Section2 = ({ firsttab, addTabs, multiSearchResult }) => {
  const viewerRefs = useRef({});
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([]);
  const [recenttab, setRecentTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ocrText, setOcrText] = useState("");
  const [isSnipping, setIsSnipping] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [snip, setSnip] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const pdfViewerRef = useRef();
  const [documentUrl, setDocumentUrl] = useState("");
  const [loadedDocs, setLoadedDocs] = useState({}); // Store loaded documents for each tab
  const [tabStates, setTabStates] = useState({});
  const history = useNavigate();
  const [currentbook, setcurrentbook] = useState({});
  const [pdfData, setPdfData] = useState(null);

  const closeTab = (id) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(updatedTabs);
    console.log("closetab");
    console.log("closetab tab left:" + updatedTabs.length);
    console.log(updatedTabs);
    if (activeTab === id && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[updatedTabs.length - 1].id);
      handleTabClick(updatedTabs.length - 1);
    } else if (updatedTabs.length === 0) {
      setActiveTab(null); // No tabs open
    }
  };
  const handleDocumentLoad = (tabId, base64Data) => {
    console.log(base64Data);
    setLoadedDocs((prevDocs) => ({
      ...prevDocs,
      [tabId]: base64Data,
    }));
    // const viewerInstance = viewerRefs.current[tabId];
    // if (viewerInstance) {
    //   viewerInstance.documentPath = base64Data; // Load the PDF into the viewer
    //   viewerInstance.load(base64Data,null);
    //       }
  };

  const loadDocumentForTab = async (tab) => {
    console.log(tab);
    if (!loadedDocs[tab.id]) {
      console.log(tab.id);
      //  const pdfData = await fetchAndCachePDF(tab.pdf_location); // Fetch and cache the PDF
      handleDocumentLoad(tab.id, tab.pdf_location); // Store loaded document
    }
  };

  // Save current viewer state (like page, zoom) before switching tabs
  const saveViewerState = (tabId) => {
    const viewerInstance = viewerRefs.current[tabId];
    if (viewerInstance) {
      const currentState = {
        currentPage: viewerInstance.currentPageNumber,
        zoom: viewerInstance.zoomPercentage,
      };
      setTabStates((prevStates) => ({
        ...prevStates,
        [tabId]: currentState,
      }));
    }
  };

  // Restore viewer state (like page, zoom) when switching tabs
  const restoreViewerState = (tabId) => {
    const viewerInstance = viewerRefs.current[tabId];
    if (viewerInstance && tabStates[tabId]) {
      viewerInstance.navigation.goToPage(tabStates[tabId].currentPage);
      viewerInstance.magnification.zoomTo(tabStates[tabId].zoom);
    }
  };

  const handleTabClick = async (index) => {
    const selectedTab = tabs[index];
    //setCurrentBook(selectedTab);
    setActiveTab(selectedTab.id);
    fetchHeadings(selectedTab);

    // Fetch and cache PDF data before loading
    await loadDocumentForTab(selectedTab);

    // Save current viewer state before switching tabs
    if (activeTab) {
      saveViewerState(activeTab);
    }

    // Restore viewer state for the selected tab
    if (tabStates[selectedTab.id]) {
      restoreViewerState(selectedTab.id);
    }
  };

  // Fetch and cache PDF
  const fetchAndCachePDF = async (url) => {
    const finalUrl =
      pdf_URL === "true"
        ? `${BASE_URL}/serve_pdf.php?file=${url.replace(
            "uploads/searchable/",
            ""
          )}`
        : `${BASE_URL}/${url}`;

    console.log("check cache", finalUrl);
    const cachedPdf = await getPDFFromIndexedDB(finalUrl);

    if (cachedPdf) {
      console.log("PDF found in cache:", cachedPdf);
      return cachedPdf; // Return cached PDF if found
    }

    // If not cached, fetch from server
    const response = await fetch(finalUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    // const viewerInstance = viewerRefs.current[activeTab];
    // if (viewerInstance) {
    //   viewerInstance.documentPath = url; // Load the PDF into the viewer
    //   viewerInstance.loadDocument();
    //       }
    return new Promise((resolve) => {
      reader.onload = async () => {
        const pdfBase64 = reader.result;
        await savePDFToIndexedDB(finalUrl, pdfBase64);
        console.log("PDF fetched and cached:", pdfBase64);
        resolve(pdfBase64); // Resolve with the base64 PDF data
      };

      reader.readAsDataURL(blob); // Read the blob as a data URL
    });
  };

  // const handleDocumentLoad = (tabId, base64Data) => {
  //   setLoadedDocs((prevDocs) => ({
  //     ...prevDocs,
  //     [tabId]: base64Data,
  //   }));
  // };

  // const loadDocumentForTab = (tab) => {
  //   console.log(tab);
  //   if (!loadedDocs[tab.id]) {
  //           console.log(tab.id);
  //           handleDocumentLoad(tab.id, tab.pdf_location); // Store loaded document

  //   }
  // };

  // // Save current viewer state (like page, zoom) before switching tabs
  // const saveViewerState = (tabId) => {
  //   const viewerInstance = viewerRefs.current[tabId];
  //   if (viewerInstance) {
  //     const currentState = {
  //       currentPage: viewerInstance.currentPageNumber,
  //       zoom: viewerInstance.zoomPercentage,
  //     };
  //     setTabStates((prevStates) => ({
  //       ...prevStates,
  //       [tabId]: currentState,
  //     }));
  //   }
  // };

  // // Restore viewer state (like page, zoom) when switching tabs
  // const restoreViewerState = (tabId) => {
  //   const viewerInstance = viewerRefs.current[tabId];
  //   if (viewerInstance && tabStates[tabId]) {
  //     viewerInstance.navigation.goToPage(tabStates[tabId].currentPage);
  //     viewerInstance.magnification.zoomTo(tabStates[tabId].zoom);
  //   }
  // };

  // const handleTabClick = (index) => {
  //   setcurrentbook(tabs[index]);
  //   setActiveTab(tabs[index].id);
  //   fetchHeadings(tabs[index]);
  //   setPdfData(tabs[index].pdf_location.replace("uploads/searchable/", ""));
  //   if (activeTab) {
  //     saveViewerState(activeTab);
  //   }
  //   setActiveTab(tabs[index].id);
  //   if (tabStates[tabs[index].id]) {
  //     restoreViewerState(tabs[index].id);
  //   } else {
  //     loadDocumentForTab(tabs[index]); // Load document if not already loaded
  //   }
  // };

  // const fetchAndCachePDF = async (url) => {

  //   const finalUrl =
  //     pdf_URL === "true"
  //       ? `${BASE_URL}/serve_pdf.php?file=${url.replace("uploads/searchable/", "")}`
  //       : `${BASE_URL}/${url}`;

  //   console.log("check cache", finalUrl);
  //   const cachedPdf = await getPDFFromIndexedDB(finalUrl);
  //   if (cachedPdf) {
  //     console.log(cachedPdf);
  //     // setPdfData(cachedPdf);
  //     // loadPdf(cachedPdf);
  //     return;
  //   }

  //   const response = await fetch(finalUrl);
  //   const blob = await response.blob();
  //   const reader = new FileReader();

  //   reader.onload = async () => {
  //     const pdfBase64 = reader.result;
  //     await savePDFToIndexedDB(finalUrl, pdfBase64);
  //     console.log(pdfBase64);
  //     // setPdfData(pdfBase64);
  //     // loadPdf(pdfBase64);
  //   };

  //   reader.readAsDataURL(blob);
  // };

  // const loadPdf = async (url) => {
  //   console.log(url);
  //   const loadingTask
  //           = `${BASE_URL}/serve_pdf.php?file=${url.replace("uploads/searchable/", "")}`
  //           ;
  //   const pdf = await loadingTask.promise;
  //   console.log(pdf.numPages)
  //   // setNumPages(pdf.numPages??1);
  // };

  const highlightText = (keyword, pageNumber) => {
    // const viewerInstance = viewerRefs.current[activeTab];
    var viewer = viewerRefs.current[activeTab];

    // Assuming we are highlighting text on page 1
    // var pageNumber = 1;
    var keyword = keyword; // The text you want to highlight

    // Find the text bounds on the specified page

    viewer.textSearchModule.findText(keyword, (result) => {
      console.log("Text not found for highlighting." + result);
      if (result && result.length > 0) {
        result.forEach((item) => {
          // Assuming item.bounds contains the bounding box
          viewer.annotation.addAnnotation("Highlight", {
            bounds: item.bounds, // Use the bounds provided by the searchText result
            pageNumber: pageNumber,
          });
        });
      } else {
        console.log("Text not found for highlighting.");
      }
    });
  };

  const handleAjaxRequestFailed = (args) => {
    // Handle AJAX request failure silently
    // console.error("AJAX request failed: ", args);

    const viewerInstance = viewerRefs.current[activeTab];

    // Unload the current document from the viewer
    if (viewerInstance) {
      var mytab = tabs.find((tab) => tab.id === activeTab);
      // viewerInstance.unload();
      viewerInstance.documentPath = `${mytab.pdf_location.replace(
        "uploads/searchable/",
        ""
      )}`;
      viewerInstance.loadDocument();
      // viewerInstance.unload();

      // Optionally, retry loading the document or show a custom notification
      // loadDocumentForTab(tabs.find(tab => tab.id === activeTab));
    }

    // Prevent showing the default error message box
    args.cancel = true;
  };

  useEffect(() => {
    console.log(addTabs);

    // Check if addTabs is not an empty object
    if (Object.keys(addTabs).length > 0) {
      addTab(addTabs); // Call addTab if addTabs is not empty
    }
  }, [addTabs]);
  useEffect(() => {
    console.log(multiSearchResult);
  }, [multiSearchResult]);

  useEffect(() => {
    console.log(firsttab);

    if (firsttab.length > 0 && firsttab[0].id && firsttab[0].title) {
      setTabs(firsttab);
      console.log("check", firsttab[0].pdf_location);
      // loadPdf(firsttab[0].pdf_location);
      setPdfData(firsttab[0].pdf_location.replace("uploads/searchable/", ""));
      console.log(pdfData);
      setActiveTab(firsttab[0].id);
      loadDocumentForTab(firsttab[0]);
    }
  }, [firsttab]);

  const addTab = (newtab) => {
    const existingTabIndex = tabs.find((tab) => tab.id === newtab.id);

    if (existingTabIndex) {
      console.log(existingTabIndex);
      if (activeTab !== existingTabIndex.id) {
        // unloadDocument();
        // loadPdf(tabs[existingTabIndex].pdf_location);
        // Tab already exists, make it active
        // setPdfData(
        //   tabs[existingTabIndex].pdf_location.replace("uploads/searchable/", "")
        // );
        //setActiveTab(existingTabIndex);
      }
    } else {
      const newTabs = [...tabs, newtab];
      setTabs(newTabs);
      setActiveTab(newtab.id);
      // if(newtab!==undefined){
      if (newtab && newtab.id && newtab.title) {
        console.log("check", newtab.pdf_location);

        setPdfData(newtab.pdf_location.replace("uploads/searchable/", ""));
        window.history.pushState(
          null,
          "",
          `/pdfViewer/${newtab.id}/${htmlToPlainText(newtab.title)}`
        );
      }
    }
    loadDocumentForTab(newtab);
    // setPdfData(newtab.)
    setcurrentbook(newtab);
    console.log(newtab);
    console.log(newtab.pdf_location);
    fetchHeadings(newtab);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isaddOpen, setIsaddOpen] = useState(false);
  const [isbookOpen, setIsbookOpen] = useState(false);
  const [isheadOpen, setIsheadOpen] = useState(false);
  const [istabOpen, setItabOpen] = useState(false);
  const [isnewOpen, setInewOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [matchCase, setMatchCase] = useState(false);
  const count = () => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    console.log("Total page count = " + viewerInstance.pageCount);
    setNumPages(viewerInstance.pageCount);
  };
  const jumpToPage = (num) => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.navigation.goToPage(num + 1);
    console.log("Total page count = " + viewerInstance.pageCount);
    console.log("Going to page  " + num);
  };
  const handleSearch = () => {
    // var viewer = document.getElementById('container').ej2_instances[0];
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.textSearchModule.searchText(searchText, matchCase); // Perform search
    console.log(
      "Search initiated for text:",
      searchText,
      "with match case:",
      matchCase
    );
  };
  const extractText = (imageUrl) => {
    Tesseract.recognize(imageUrl, "heb", {
      logger: (m) => console.log(m),
    }).then(({ data: { text } }) => {
      setOcrText(text);
      openModal();

      resetSelection(); // Reset selection state
    });
  };
  const handleMouseDown = (e) => {
    if (!isSnipping) return;
    const rect = pdfViewerRef.current.getBoundingClientRect();
    setStartPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    setEndPoint(null);
  };

  const handleMouseMove = (e) => {
    if (!isSnipping || !startPoint) return;
    const rect = pdfViewerRef.current.getBoundingClientRect();
    setEndPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleMouseUp = () => {
    if (!isSnipping || !startPoint || !endPoint) return;
    captureSnip();
    setIsSnipping(false); // Stop snipping after selection
  };

  const captureSnip = () => {
    const { x: startX, y: startY } = startPoint;
    const { x: endX, y: endY } = endPoint;
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);

    const pdfViewerElement = pdfViewerRef.current;

    // Hide selection box before capturing
    const selectionBox = document.getElementById("selection-box");
    if (selectionBox) selectionBox.style.display = "none";

    html2canvas(pdfViewerElement, {
      x: Math.min(startX, endX),
      y: Math.min(startY, endY),
      width,
      height,
      scrollX: 0,
      scrollY: 0,
    })
      .then((canvas) => {
        const snipDataUrl = canvas.toDataURL();
        setSnip(snipDataUrl);
        extractText(snipDataUrl);
      })
      .finally(() => {
        // Show selection box after capturing
        if (selectionBox) selectionBox.style.display = "block";
      });
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(ocrText).then(() => {
      alert("Text copied to clipboard");
    });
  };
  const copylink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      alert("Link copied to clipboard");
    });
  };

  const saveImage = () => {
    if (snip) {
      saveAs(snip, "snipped_image.png");
    }
  };

  const printImage = () => {
    if (snip) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${snip}" style="width: 100%;" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    }
  };

  const resetSelection = () => {
    setStartPoint(null);
    setEndPoint(null);
    setIsSnipping(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const openaddModal = () => {
    setIsaddOpen(true);
  };

  const closeaddModal = () => {
    setIsaddOpen(false);
  };
  const openbookModal = () => {
    setIsbookOpen(true);
  };

  const closebookModal = () => {
    setIsbookOpen(false);
  };
  const openheadModal = () => {
    setIsheadOpen(true);
  };

  const closeheadModal = () => {
    setIsheadOpen(false);
  };
  const openTabModal = () => {
    setItabOpen(true);
  };

  const closeTabModal = () => {
    setItabOpen(false);
  };
  const opennewModal = () => {
    setInewOpen(true);
  };

  const closenewModal = () => {
    setInewOpen(false);
  };
  const [activeAccordion1, setActiveAccordion1] = useState(true);
  const [activeAccordion2, setActiveAccordion2] = useState(false);
  const [activeAccordion3, setActiveAccordion3] = useState(false);
  const [activeAccordion4, setActiveAccordion4] = useState(false);
  

  const toggleAccordion = (accordionId) => {
    // Close all accordions
    setActiveAccordion1(false);
    setActiveAccordion2(false);
    setActiveAccordion3(false);
    setActiveAccordion4(false);

    // Toggle the selected accordion
    switch (accordionId) {
      case "accordion-open-body-1":
        setActiveAccordion1(true);
        break;
      case "accordion-open-body-2":
        setActiveAccordion2(true);
        break;
      case "accordion-open-body-3":
        setActiveAccordion3(true);
        break;
      case "accordion-open-body-4":
        setActiveAccordion4(true);
        
        break;
      default:
        break;
    }
  };
  // const checkIfFavorite = () => {
  //   console.log(currentUser.id);
  //   const userData = getUserData();

  //   axios
  //     .get(`${BASE_URL}/api_book.php/favoritebooks?user_id=${userData.id}&book_id=${id}`)
  //     .then((res) => {
  //       console.log(res.data);

  //       setIsFavorite(res.data.length > 0);
  //       setFavorite(res.data[0].id);
  //        console.log(res.data[0].id); // Update isFavorite state based on API response
  //     })
  //     .catch((err) => console.log(err));
  // };
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level (in percentage)
  const handleZoomIn = () => {
    if (zoomLevel < 200) {
      // Limit maximum zoom to 200%
      setZoomLevel(zoomLevel + 10);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 50) {
      // Limit minimum zoom to 50%
      setZoomLevel(zoomLevel - 10);
    }
  };

  const highlightPluginInstance = highlightPlugin({});
  const [selectedTab, setSelectedTab] = useState(0); // State to track active tab index
  const currentPageInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlights, setHighlights] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState({
    keyword: "",
    matchCase: false,
    wholeWords: false,
  });
  const [bookmarks, setBookmarks] = useState([]);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin();
  const pageRenderInitiate = (args) => {
    // This method is called when the page rendering starts
    console.log("Rendering of pages started");
    console.log(args);
  };
  const pageRenderComplete = (args) => {
    // This method is called when the page rendering completes
    console.log("Rendering of pages completed");
    console.log(args);
  };
  const handletext = (args) => {
    console.log("search:", args);
  };

  const nextTextSearch = () => {
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.textSearchModule.searchNext();
  };

  const previousTextSearch = () => {
    const viewerInstance = viewerRefs.current[activeTab];
    viewerInstance.textSearchModule.searchPrevious();
  };

  const initiateTextSearch = (current) => {
    const viewerInstance = viewerRefs.current[activeTab]; // Get the active PDF viewer instance

    if (viewerInstance) {
      // highlightText(current.keyword)
      // Set custom text search highlight colors
      // viewerInstance.textSearchColorSettings = {
      //     currentOccurrenceHighlightColor: '#bdf268',  // Custom color for the currently selected match
      //     otherOccurrenceHighlightColor: '#f2eb68'     // Custom color for other matches
      // };

      // Start the text search with the keyword provided
      viewerInstance.textSearchModule.searchText(current.keyword, {
        matchCase: current.matchCase || false,
        wholeWords: current.wholeWords || false,
      });
    }
    // Show loading

    // viewerInstance.textSearchModule.cancelTextSearch(); // Clear previous search
    // viewerInstance.textSearchModule.searchText(current.keyword, true,);
  };
  const { highlight, jumpToNextMatch, jumpToPreviousMatch, setTargetPages } =
    searchPluginInstance;
  const { jumpToHighlightArea } = highlightPluginInstance;
  const reverseKeyword = (keyword) => {
    console.log(keyword);
    if (typeof keyword.keyword === "string") {
      return {
        keyword: keyword.keyword.split("").reverse().join(""),
        matchCase: keyword.matchCase,
        wholeWords: keyword.wholeWords,
      };
    }
    // If keyword is not a string, return it unchanged or handle it as needed
    console.error("Keyword is not a string:", keyword);
    return keyword;
  };
  const search = (keyword) => {
    const reversedKeyword = reverseKeyword(keyword);
    setTargetPages((targetPage) => targetPage.numPages === numPages);
    //setCurrentKeyword(reversedKeyword);
    highlight(reversedKeyword);
  };
  const customsearch = (keyword, page) => {
    const reversedKeyword = reverseKeyword(keyword);
    setTargetPages((targetPage) => targetPage.pageIndex === page);
    highlight(reversedKeyword);
  };
  const changetopic = (keyword) => {
    setCurrentKeyword(keyword);
    highlight(keyword);
  };
  const handleAjaxRequestSuccess = (args) => {
    // console.log("AJAX request succeeded:", args);

    // Handle additional success logic here
    const viewerInstance = viewerRefs.current[activeTab];

    // For example, check if it's loading a specific page or if the document has fully loaded
    if (args.action === "RenderPdfPages" && args.status === 200) {
      console.log("Page successfully rendered.");
    }

    // You can also check if additional data has been passed
    if (args.data) {
      // console.log("Received additional data:", args.data);
      if (args.data.pageCount) {
        setNumPages(args.data.pageCount);
      }
    }
  };

  // const handleSearch = () => {
  //   search({
  //     keyword: searchTerm,
  //     matchCase: currentKeyword.matchCase,
  //     wholeWords: currentKeyword.wholeWords,
  //   });
  // };

  useEffect(() => {
    fetchHeadings(activeTab);
  }, [activeTab]);
  // const fetchPdf = async (tab) => {
  //   try {
  //     const response = await fetch(`${pdf_URL}${tab}`);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch PDF");
  //     }
  //     const blob = await response.blob();
  //     const objectUrl = URL.createObjectURL(blob);
  //     return objectUrl;
  //   } catch (error) {
  //     console.error("Error fetching PDF:", error);
  //   }
  // };
  const fetchHeadings = async (tab) => {
    setBookmarks([]);
    if (pdfData) {
      // console.log("location", `../../../api/${tab.pdf_location}`);
      try {
        // const pdfDocument = await pdfjs.getDocument(
        //   pdfData
        // ).promise;
        // const numPages = pdfDocument.numPages;
        // setNumPages(pdfDocument.numPages);
        let url = `${BASE_URL}/api_books_indexes.php?book_id=${tab.id}`;
        const response = await axios.get(url);
        const volumeData = response.data;
        console.log(response.data);
        if (Array.isArray(volumeData)) {
          setBookmarks(volumeData);
        } else {
          console.error("Expected an array of volumes");
        }

        // const headings = [];

        // const regex = /[\u0590-\u05FF]+/g; // Regex for Hebrew characters

        // for (let i = 1; i <= numPages; i++) {
        //   const page = await pdfDocument.getPage(i);
        //   const textContent = await page.getTextContent();

        //   const firstHeadingItem = textContent.items.find((item) => {
        //     const matches = item.str.match(regex);
        //     return matches && matches.length > 0; // Adjust this condition based on your PDF's headings structure
        //   });

        //   if (firstHeadingItem) {
        //     headings.push({
        //       title: firstHeadingItem.str.trim(),
        //       pageIndex: i - 1, // pageIndex is zero-based for react-pdf-viewer
        //       index: firstHeadingItem.transform[5], // Adjust this based on where your heading index is stored
        //     });
        //   }
        // }

        // setBookmarks(headings);
      } catch (error) {
        console.error("Error fetching headings:", error);
        setBookmarks([]);
      }
    }
  };

  // componentDidMount() {
  //   viewer = document.getElementById('container').ej2_instances[0];
  // }
  const copyImageToClipboard = () => {
    if (snip) {
      fetch(snip)
        .then((response) => response.blob())
        .then((blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([item]);
        })
        .then(() => {
          alert("Image copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy image: ", err);
        });
    }
  };
  return (
    <div className="bg-gray-100">
      <div className="bg-[#F7F7F7] shadow-md">
        <div className="flex items-center  justify-between px-4 py-0 pt-4">
          <div className="flex space-x-2  w-full" dir="rtl">
            <div className="overflow-x-auto	w-auto flex">
              {tabs.map((title, index) => (
                <div
                  key={index}
                  className={`cursor-pointer min-w-[90px] border-t border-l border-r border-gray-300 w-full flex px-3  rounded-t-lg ${
                    activeTab === tabs[index].id ? "bg-white  " : "bg-[#F7F7F7]"
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  <span className="text-[10px] m-auto truncate  w-[95%]">
                    {htmlToPlainText(title.title)}
                  </span>
                  <button
                    onClick={() => closeTab(tabs[index].id)}
                    className="ml-2  w-[5%] text-sm text-gray-500 hover:text-gray-800 focus:outline-none"
                  >
                    <IoClose className="w-4 h-4 inline hover:text-red-500" />
                  </button>
                </div>
              ))}
            </div>
            <div className="w-auto">
              <button
                onClick={openTabModal}
                className="px-3 mr-2  bg-[#6D3815] text-white rounded-t-lg hover:bg-red-600 focus:outline-none"
              >
                <span className="text-sm">+</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        direction={"rtl"}
        selected={activeTab}
        onSelect={() => {
          handleTabClick();
        }}
      >
        {tabs.map((tab, index) => (
          <TabPanel key={index} className="">
            <div className="flex">
              <div className="w-[5%]">
                <div className="pdf-vertical-bar p-4">
                  <div className="flex flex-col">
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button className="mb-2 p-2 hover:bg-gray-300 rounded-md">
                        <RiPageSeparator />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("All_Pages")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* start buton */}
                    {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><MdOutlineFitScreen />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Full Screen</span>
    </div>
    {/* End button */}
                    {/* start buton */}
                    {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomOut />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom Out</span>
    </div> */}
                    {/* End button */}

                    {/* start buton */}
                    {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomIn />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom In</span>
    </div> */}
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        onClick={() => {
                          setIsSnipping(true);
                          // openModal
                        }}
                        className="mb-2 	 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <IoMdCut />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Text_or_Image")}
                      </span>
                    </div>
                    {isOpen && (
                      <div className="fixed inset-0 flex items-center justify-center z-[199]">
                        {/* Background overlay */}
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                        {/* Modal dialog */}
                        <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                          {/* Header */}
                          <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                            <h2 className="text-lg font-semibold">
                              {t("Marker_Options")}
                            </h2>
                            <button
                              onClick={closeModal}
                              className="text-gray-700 hover:text-gray-900"
                            >
                              <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Content */}
                          <div className="w-full">
                            <div className="flex w-[90%] m-auto">
                              <div className="w-[45%] m-auto img-maekr">
                                <p className="mb-2 mt-2">{t(":image")}</p>
                                <img
                                  src={snip}
                                  alt="snip"
                                  style={{ maxWidth: "100%" }}
                                />
                                {/* <input className="border h-[100px] w-[250px] border-black ax" name="image"  /> */}
                              </div>
                              <div className="w-[45%] m-auto img-maekr">
                                <p className="mb-2 mt-2 flex items-center">
                                  {t(":text(OCR)")}
                                  <button
                                    className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                    onClick={handleZoomIn}
                                  >
                                    +
                                  </button>
                                  <button
                                    className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                    onClick={handleZoomOut}
                                  >
                                    -
                                  </button>
                                </p>
                                <p
                                  style={{ fontSize: `${zoomLevel}%` }}
                                  className="border-black border p-4 pt-0"
                                >
                                  {ocrText}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* Footer */}
                          <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                            <button
                              className="px-4 py-1  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                              onClick={copyToClipboard}
                            >
                              {t("Copy_Text")}
                            </button>
                            <button
                              className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                              onClick={saveImage}
                            >
                              {t("Save_image")}
                            </button>
                            <button
                              className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                              onClick={printImage}
                            >
                              {t("print")}
                            </button>
                            {/* <button
          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
        >
       Create a marker 
        </button> */}
                            <button
                              className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                              onClick={copyImageToClipboard}
                            >
                              {t("copy_img")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      {/* <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <MdPrint />
                      </button> */}
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("print")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        onClick={openaddModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <GrChapterAdd />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("User_add-ons")}
                      </span>
                    </div>
                    {/* modal content */}
                    {isaddOpen && (
                      <div className="fixed inset-0 flex items-center h-[600px]  justify-left left-[47px] top-[138px] z-[199]">
                        {/* Background overlay */}
                        {/* <div className="absolute inset-0 bg-gray-500 opacity-75"></div> */}

                        {/* Modal dialog */}
                        <div className="relative bg-white w-[20%] h-[600px] left-0 rounded-lg shadow-lg  pt-0">
                          {/* Header */}
                          <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                            <h2 className="text-lg font-semibold">
                              {t("Marker_Options")}
                            </h2>
                            <button
                              onClick={closeaddModal}
                              className=" hover:text-gray-900"
                            >
                              <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Content */}
                          <div
                            id="accordion-open"
                            data-accordion="open"
                            dir="rtl"
                          >
                            {/* Accordion 1 */}
                            <div>
                              <div className="flex border-b-0 border-[#dedede] border   hover:bg-[#f7f7f7] gap-1">
                                <button className=" text-[#3b200f]  mr-2 rounded">
                                  <SiImessage />
                                </button>
                                <button
                                  type="button"
                                  className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
                                    activeAccordion1 ? "bg-[#f7f7f7]" : ""
                                  }`}
                                  data-accordion-target="#accordion-open-body-1"
                                  aria-expanded={activeAccordion1}
                                  onClick={() =>
                                    toggleAccordion("accordion-open-body-1")
                                  }
                                >
                                  <span className="flex items-center justify-between w-full">
                                    <span className="flex items-center">
                                      {t("Note")}
                                    </span>
                                    <IoMdArrowDropup
                                      className={`w-5 h-5 me-2 shrink-0 transform ${
                                        activeAccordion1
                                          ? "rotate-0"
                                          : "rotate-180"
                                      }`}
                                    />
                                  </span>
                                </button>
                              </div>
                              <div
                                id="accordion-open-body-1"
                                className={`p-2 bg-white h-[200px] ${
                                  activeAccordion1 ? "block" : "hidden"
                                }`}
                                aria-labelledby="accordion-open-heading-1"
                              >
                                <div className="text-gray-500">
                                  <div className="relative">
                                    <input
                                      type="search"
                                      id="Searchbooks"
                                      placeholder={t("search_in_note")}
                                      dir="rtl"
                                      className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                    />

                                    <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                      <button
                                        type="button"
                                        className="text-black  hover:text-gray-700"
                                      >
                                        <span className="sr-only">Search</span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="h-3 w-3"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                          />
                                        </svg>
                                      </button>
                                    </span>
                                  </div>
                                  <div
                                    className="max-w-xs mx-auto overflow-y-auto mt-2"
                                    dir="ltr"
                                  >
                                    <ul
                                      className="flex flex-col h-[160px] pr-2 space-y-1"
                                      dir="rtl"
                                    >
                                      <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">{t("Note")}</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                      <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                        <a className=""> {t("Note")}</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Accordion 2 */}
                            <div>
                              <div className="flex overflow-auto border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                <button className=" text-[#3b200f]  mr-2 rounded">
                                  <FaKey />
                                </button>
                                <button
                                  type="button"
                                  className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   hover:bg-[#f7f7f7] gap-3 ${
                                    activeAccordion2 ? "bg-[#f7f7f7]" : ""
                                  }`}
                                  data-accordion-target="#accordion-open-body-2"
                                  aria-expanded={activeAccordion2}
                                  onClick={() =>
                                    toggleAccordion("accordion-open-body-2")
                                  }
                                >
                                  <span className="flex items-center justify-between w-full">
                                    <span className="flex items-center">
                                      {t("Indexes")}
                                    </span>
                                    <IoMdArrowDropup
                                      className={`w-5 h-5 me-2 shrink-0 transform ${
                                        activeAccordion2
                                          ? "rotate-0"
                                          : "rotate-180"
                                      }`}
                                    />
                                  </span>
                                </button>
                              </div>
                              <div
                                id="accordion-open-body-2"
                                className={`p-2 bg-white h-[200px] ${
                                  activeAccordion2 ? "block" : "hidden"
                                }`}
                                aria-labelledby="accordion-open-heading-2"
                              >
                                <div className="text-gray-500">
                                  <div className="relative">
                                    <input
                                      type="search"
                                      id="Searchbooks"
                                      placeholder="keys"
                                      dir="rtl"
                                      className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                    />

                                    <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                      <button
                                        type="button"
                                        className="text-black  hover:text-gray-700"
                                      >
                                        <span className="sr-only">Search</span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="h-3 w-3"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                          />
                                        </svg>
                                      </button>
                                    </span>
                                  </div>
                                  <div
                                    className="max-w-xs mx-auto overflow-y-auto mt-2"
                                    dir="ltr"
                                  >
                                    <ul
                                      className="flex flex-col h-[160px] pr-2 space-y-1"
                                      dir="rtl"
                                    >
                                      <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">Key 1</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                      <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">Key 2</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Accordion 3 */}
                            <div>
                              <div className="flex border-b-0 border-[#dedede] border hover:bg-[#f7f7f7] gap-1">
                                <button className=" text-[#3b200f]  mr-2 rounded">
                                  <FaMarker />
                                </button>
                                <button
                                  type="button"
                                  className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                    activeAccordion3 ? "bg-[#f7f7f7]" : ""
                                  }`}
                                  data-accordion-target="#accordion-open-body-3"
                                  aria-expanded={activeAccordion3}
                                  onClick={() =>
                                    toggleAccordion("accordion-open-body-3")
                                  }
                                >
                                  <span className="flex items-center justify-between w-full">
                                    <span className="flex items-center">
                                      {t("Marker")}
                                    </span>
                                    <IoMdArrowDropup
                                      className={`w-5 h-5 me-2 shrink-0 transform ${
                                        activeAccordion3
                                          ? "rotate-0"
                                          : "rotate-180"
                                      }`}
                                    />
                                  </span>
                                </button>
                              </div>
                              <div
                                id="accordion-open-body-3"
                                className={`p-2 bg-white h-[200px] ${
                                  activeAccordion3 ? "block" : "hidden"
                                }`}
                                aria-labelledby="accordion-open-heading-3"
                              >
                                <div className="text-gray-500">
                                  <div
                                    className="max-w-xs mx-auto overflow-y-auto mt-2"
                                    dir="ltr"
                                  >
                                    <ul
                                      className="flex flex-col h-[160px] pr-2 space-y-1"
                                      dir="rtl"
                                    >
                                      <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">{t("Marker")}</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                      <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                        <a className=""> {t("Marker")}</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Accordion 4 */}
                            <div>
                              <div className="flex border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                <button className=" text-[#3b200f]  mr-2 rounded">
                                  <FaLink />
                                </button>
                                <button
                                  type="button"
                                  className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                    activeAccordion4 ? "bg-[#f7f7f7]" : ""
                                  }`}
                                  data-accordion-target="#accordion-open-body-4"
                                  aria-expanded={activeAccordion4}
                                  onClick={() =>
                                    toggleAccordion("accordion-open-body-4")
                                  }
                                >
                                  <span className="flex items-center justify-between w-full">
                                    <span className="flex items-center">
                                      {t("Internal_Links")}
                                    </span>
                                    <IoMdArrowDropup
                                      className={`w-5 h-5 me-2 shrink-0 transform ${
                                        activeAccordion4
                                          ? "rotate-0"
                                          : "rotate-180"
                                      }`}
                                    />
                                  </span>
                                </button>
                              </div>
                              <div
                                id="accordion-open-body-4"
                                className={`p-2 bg-white h-[200px] ${
                                  activeAccordion4 ? "block" : "hidden"
                                }`}
                                aria-labelledby="accordion-open-heading-4"
                              >
                                <div className="text-gray-500">
                                  <div
                                    className="max-w-xs mx-auto overflow-y-auto mt-2"
                                    dir="ltr"
                                  >
                                    <ul
                                      className="flex flex-col h-[160px] pr-2 space-y-1"
                                      dir="rtl"
                                    >
                                      <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">Link</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                      <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                        <a className="">Link</a>
                                        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                          <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                            <AiOutlineDelete className="h-4 w-4" />
                                          </button>
                                          <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                            <AiOutlineEdit className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Footer */}
                        </div>
                      </div>
                    )}
                    {/* modal content end */}

                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                        onClick={copylink}
                      >
                        <FaLink />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Create_a_link")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <FaRegHeart />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("add_fav")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <IoLocationOutline />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Hyperlinks")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        onClick={opennewModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <VscEmptyWindow />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Open_book_in_a_new_tab")}
                      </span>
                    </div>
                    {/* End button */}
                    {/* Start Tab Modal */}
                    {isnewOpen && (
                      <div className="fixed  inset-0 flex items-center justify-center z-[199]">
                        {/* Background overlay */}
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                        {/* Modal dialog */}
                        <div className="relative w-[80%] overflow-auto h-[80%] bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                          {/* Header */}
                          <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
                            <h2 className="text-lg font-semibold">
                              {" "}
                              {t(" book_name")}
                            </h2>
                            <button
                              onClick={closenewModal}
                              className="text-gray-700 hover:text-gray-900"
                            >
                              <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Content */}
                          <div className="flex">
                            <div className="w-[5%]">
                              <div className="pdf-vertical-bar p-4">
                                <div className="flex flex-col">
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300 rounded-md">
                                      <RiPageSeparator />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("All_Pages")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><MdOutlineFitScreen />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Full Screen</span>
    </div>
    {/* End button */}
                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomOut />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom Out</span>
    </div> */}
                                  {/* End button */}

                                  {/* start buton */}
                                  {/* <div class="group relative flex w-full justify-center">
    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md"><AiOutlineZoomIn />
    </button>
    <span class="absolute top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
    Zoom In</span>
    </div> */}
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={() => {
                                        setIsSnipping(true);
                                        // openModal
                                      }}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <IoMdCut />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Text_or_Image")}
                                    </span>
                                  </div>
                                  {isOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Marker_Options")}
                                          </h2>
                                          <button
                                            onClick={closeModal}
                                            className="text-gray-700 hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="w-full">
                                          <div className="flex w-[90%] m-auto">
                                            <div className="w-[45%] m-auto img-maekr">
                                              <p className="mb-2 mt-2">
                                                {t(":image")}
                                              </p>
                                              <img
                                                src={snip}
                                                alt="snip"
                                                style={{ maxWidth: "100%" }}
                                              />
                                              {/* <input className="border h-[100px] w-[250px] border-black ax" name="image"  /> */}
                                            </div>
                                            <div className="w-[45%] m-auto img-maekr">
                                              <p className="mb-2 mt-2 flex items-center">
                                                {t(":text(OCR)")}
                                                <button
                                                  className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                  onClick={handleZoomIn}
                                                >
                                                  +
                                                </button>
                                                <button
                                                  className="py-0 px-4 rounded-full text-white ml-2 mr-2 bg-[#6D3815] cursor-pointer"
                                                  onClick={handleZoomOut}
                                                >
                                                  -
                                                </button>
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: `${zoomLevel}%`,
                                                }}
                                                className="border-black border p-4 pt-0"
                                              >
                                                {ocrText}
                                              </p>
                                              <p>{ocrText}</p>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Footer */}
                                        <div className="mt-4 bg-[#E9E9E9] p-4 flex justify-center">
                                          <button
                                            className="px-4 py-1  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={copyToClipboard}
                                          >
                                            {t("Copy_Text")}
                                          </button>
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={saveImage}
                                          >
                                            {t("Save_image")}
                                          </button>
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={printImage}
                                          >
                                            {t("print")}
                                          </button>
                                          {/* <button
          className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
        >
       Create a marker 
        </button> */}
                                          <button
                                            className="px-4 py-1 ml-4  bg-[#6D3815] text-[12px] text-white hover:bg-red-800  rounded-full focus:outline-none"
                                            onClick={copyImageToClipboard}
                                          >
                                            {t("copy_img")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                      <MdPrint />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("print")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={openaddModal}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <GrChapterAdd />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("User_add-ons")}
                                    </span>
                                  </div>
                                  {/* modal content */}
                                  {isaddOpen && (
                                    <div className="fixed inset-0 flex items-center h-[600px]  justify-left left-[47px] top-[138px] z-[199]">
                                      {/* Background overlay */}
                                      {/* <div className="absolute inset-0 bg-gray-500 opacity-75"></div> */}

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[20%] h-[600px] left-0 rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Marker_Options")}
                                          </h2>
                                          <button
                                            onClick={closeaddModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div
                                          id="accordion-open"
                                          data-accordion="open"
                                          dir="rtl"
                                        >
                                          {/* Accordion 1 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border   hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <SiImessage />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
                                                  activeAccordion1
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-1"
                                                aria-expanded={activeAccordion1}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-1"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Note")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion1
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-1"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion1
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-1"
                                            >
                                              <div className="text-gray-500">
                                                <div className="relative">
                                                  <input
                                                    type="search"
                                                    id="Searchbooks"
                                                    placeholder="Search in notes"
                                                    dir="rtl"
                                                    className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                  />

                                                  <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                    <button
                                                      type="button"
                                                      className="text-black  hover:text-gray-700"
                                                    >
                                                      <span className="sr-only">
                                                        Search
                                                      </span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-3 w-3"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </span>
                                                </div>
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Note")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Note")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Accordion 2 */}
                                          <div>
                                            <div className="flex overflow-auto border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaKey />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]   hover:bg-[#f7f7f7] gap-3 ${
                                                  activeAccordion2
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-2"
                                                aria-expanded={activeAccordion2}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-2"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Indexes")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion2
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-2"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion2
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-2"
                                            >
                                              <div className="text-gray-500">
                                                <div className="relative">
                                                  <input
                                                    type="search"
                                                    id="Searchbooks"
                                                    placeholder="keys"
                                                    dir="rtl"
                                                    className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
                                                  />

                                                  <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                                                    <button
                                                      type="button"
                                                      className="text-black  hover:text-gray-700"
                                                    >
                                                      <span className="sr-only">
                                                        Search
                                                      </span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="h-3 w-3"
                                                      >
                                                        <path
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </span>
                                                </div>
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Key 1</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">Key 2</a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Accordion 3 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaMarker />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                  activeAccordion3
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-3"
                                                aria-expanded={activeAccordion3}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-3"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Marker")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion3
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-3"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion3
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-3"
                                            >
                                              <div className="text-gray-500">
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Marker")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Marker")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* Accordion 4 */}
                                          <div>
                                            <div className="flex border-b-0 border-[#dedede] border  hover:bg-[#f7f7f7] gap-1">
                                              <button className=" text-[#3b200f]  mr-2 rounded">
                                                <FaLink />
                                              </button>
                                              <button
                                                type="button"
                                                className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
                                                  activeAccordion4
                                                    ? "bg-[#f7f7f7]"
                                                    : ""
                                                }`}
                                                data-accordion-target="#accordion-open-body-4"
                                                aria-expanded={activeAccordion4}
                                                onClick={() =>
                                                  toggleAccordion(
                                                    "accordion-open-body-4"
                                                  )
                                                }
                                              >
                                                <span className="flex items-center justify-between w-full">
                                                  <span className="flex items-center">
                                                    {t("Internal_Links")}
                                                  </span>
                                                  <IoMdArrowDropup
                                                    className={`w-5 h-5 me-2 shrink-0 transform ${
                                                      activeAccordion4
                                                        ? "rotate-0"
                                                        : "rotate-180"
                                                    }`}
                                                  />
                                                </span>
                                              </button>
                                            </div>
                                            <div
                                              id="accordion-open-body-4"
                                              className={`p-2 bg-white h-[200px] ${
                                                activeAccordion4
                                                  ? "block"
                                                  : "hidden"
                                              }`}
                                              aria-labelledby="accordion-open-heading-4"
                                            >
                                              <div className="text-gray-500">
                                                <div
                                                  className="max-w-xs mx-auto overflow-y-auto mt-2"
                                                  dir="ltr"
                                                >
                                                  <ul
                                                    className="flex flex-col h-[160px] pr-2 space-y-1"
                                                    dir="rtl"
                                                  >
                                                    <li className="flex items-center justify-between bg-gray-200 hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Link")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-blue-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                    <li className="flex items-center justify-between bg-gray-200  hover:bg-gray-300 px-2 py-1 relative">
                                                      <a className="">
                                                        {t("Link")}
                                                      </a>
                                                      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex space-x-2">
                                                        <button className="text-gray-600 hover:text-red-600 focus:outline-none">
                                                          <AiOutlineDelete className="h-4 w-4" />
                                                        </button>
                                                        <button className="text-gray-600 hover:text-brown-600 focus:outline-none">
                                                          <AiOutlineEdit className="h-4 w-4" />
                                                        </button>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Footer */}
                                      </div>
                                    </div>
                                  )}
                                  {/* modal content end */}

                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                      <FaLink />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Create_link_page")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                      <FaRegHeart />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("add_fav")}
                                    </span>
                                  </div>
                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                                      <IoLocationOutline />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Hyperlinks")}
                                    </span>
                                  </div>
                                  {/* End button */}

                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={() => {
                                        openbookModal();
                                      }}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <FaBook />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Book_Detail")}
                                    </span>
                                  </div>
                                  {isbookOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Book_Detail")}
                                          </h2>
                                          <button
                                            onClick={closebookModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="flex w-full p-4 h-[300px]">
                                          <div className="w-[30%]">
                                            <img src="..//images/book.png" />
                                          </div>
                                          <div className="w-[70%]">
                                            <div className="max-w-md mx-auto  bg-white rounded-lg shadow-md overflow-hidden">
                                              {/* Tab List */}
                                              <Tabs>
                                                <TabList className="flex p-4 space-x-2 bg-gray-100">
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 0
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(0)
                                                    }
                                                  >
                                                    {t("Otzar_HaChochma")}
                                                  </Tab>
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 1
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(1)
                                                    }
                                                  >
                                                    {t("Bibliographic_Data")}
                                                  </Tab>
                                                  <Tab
                                                    className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                                      selectedTab === 2
                                                        ? "border-b-2 border-red-500"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedTab(2)
                                                    }
                                                  >
                                                    {t(
                                                      "National_Library_of_Israel"
                                                    )}
                                                  </Tab>
                                                </TabList>

                                                {/* Tab Panels */}
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 0
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <div>
                                                    <ul>
                                                      <li className="p-4 border-b border-gray-600">
                                                        {t("book_name")}:{" "}
                                                        {htmlToPlainText(
                                                          currentbook.title
                                                        )}
                                                      </li>
                                                      <li className="p-4 border-b border-gray-600">
                                                        {t("arthor_name")}:{" "}
                                                        {htmlToPlainText(
                                                          currentbook.auther
                                                        )}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </TabPanel>
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 1
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <ul>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("book_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.title
                                                      )}
                                                    </li>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("arthor_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.auther
                                                      )}
                                                    </li>
                                                  </ul>
                                                </TabPanel>
                                                <TabPanel
                                                  className={`p-4 ${
                                                    selectedTab === 2
                                                      ? ""
                                                      : "hidden"
                                                  } overflow-auto h-full`}
                                                >
                                                  <ul>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("book_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.title
                                                      )}
                                                    </li>
                                                    <li className="p-4 border-b border-gray-600">
                                                      {t("arthor_name")}:{" "}
                                                      {htmlToPlainText(
                                                        currentbook.auther
                                                      )}
                                                    </li>
                                                  </ul>
                                                </TabPanel>
                                              </Tabs>
                                            </div>
                                          </div>
                                        </div>
                                        {/* content */}
                                      </div>
                                    </div>
                                  )}

                                  {/* End button */}
                                  {/* start buton */}
                                  <div class="group relative flex w-full justify-center">
                                    <button
                                      onClick={openheadModal}
                                      className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                                    >
                                      <BsCardHeading />
                                    </button>
                                    <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                      {t("Show_Page_Headings")}
                                    </span>
                                  </div>
                                  {isheadOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center z-[199]">
                                      {/* Background overlay */}
                                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                                      {/* Modal dialog */}
                                      <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                                        {/* Header */}
                                        <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                                          <h2 className="text-lg font-semibold">
                                            {t("Page_headings")}
                                          </h2>
                                          <button
                                            onClick={closeheadModal}
                                            className=" hover:text-gray-900"
                                          >
                                            <svg
                                              className="h-6 w-6 fill-current"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                        </div>

                                        {/* Content */}
                                        <div className="flex w-full p-4 h-[300px]">
                                          <ul
                                            dir="rtl"
                                            className="overflow-auto h-[280px] m-auto"
                                          >
                                            {bookmarks.map(
                                              (bookmark, index) => (
                                                <li
                                                  key={index}
                                                  className=" hover:bg-gray-300"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      jumpToPage(
                                                        bookmark.pageNum - 1
                                                      );
                                                      setIsheadOpen(false);
                                                    }}
                                                  >
                                                    {htmlToPlainText(
                                                      bookmark.index
                                                    )}
                                                  </button>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                        {/* content */}
                                      </div>
                                    </div>
                                  )}
                                  {/* End button */}
                                </div>
                              </div>
                            </div>
                            {tabs.map(
                              (tab) =>
                                activeTab === tab.id && (
                                  <div className="w-[75%]">
                                    {/*second pdfviewer */}
                                    <div
                                      key={tab.id}
                                      className="flex items-center justify-center h-[82vh]"
                                    >
                                      <h2>Search:</h2>
                                      <div>
                                        <input
                                          type="text"
                                          id="container_search_input"
                                          value={searchText}
                                          onChange={(e) =>
                                            setSearchText(e.target.value)
                                          }
                                          placeholder="Enter text to search"
                                        />

                                        <button
                                          id="container_search_box-icon"
                                          onClick={initiateTextSearch}
                                        >
                                          Search
                                        </button>
                                      </div>

                                      <div>
                                        <button
                                          id="container_prev_occurrence"
                                          onClick={previousTextSearch}
                                        >
                                          Previous
                                        </button>
                                        <button
                                          id="container_next_occurrence"
                                          onClick={nextTextSearch}
                                        >
                                          Next
                                        </button>
                                      </div>
                                      <div
                                        ref={pdfViewerRef}
                                        style={{
                                          position: "relative",
                                          width: "100vw",
                                          height: "82vh",
                                        }}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUp}
                                      >
                                        <PdfViewerComponent
                                          id={`pdf-viewer-${tab.id}`}
                                          ref={(ref) =>
                                            (viewerRefs.current[tab.id] = ref)
                                          }
                                          pageRenderInitiate={
                                            pageRenderInitiate
                                          }
                                          pageRenderComplete={
                                            pageRenderComplete
                                          }
                                          //  documentPath={`https://oizvehadar.com/api/serve_pdf.php?file=${loadedDocs[tab.id] || ""}`}

                                          //    resourceUrl = "https://cdn.syncfusion.com/ej2/27.1.52/dist/ej2-pdfviewer-lib"
                                          documentPath={
                                            loadedDocs[tab.id] || ""
                                          }
                                          serviceUrl="https://oizvehadar.com/pdfsync1/pdfviewer"
                                          retryTimeout={10}
                                          retryCount={5}
                                          initialRenderPages={50}
                                          textSearchComplete={() => handletext}
                                          // PageRenderInitiate={count}
                                          ajaxRequestSuccess={
                                            handleAjaxRequestSuccess
                                          }
                                          ajaxRequestFailed={
                                            handleAjaxRequestFailed
                                          }
                                          showNotificationDialog={false}
                                          height="80vh"
                                          enableRtl="true"
                                          enableBookmark={true}
                                          enableTextSearch={true}
                                          toolbarSettings={{
                                            showTooltip: true,
                                            toolbarItems: [
                                              "PageNavigationTool",
                                              "MagnificationTool",
                                              //   'PanTool',
                                              //   'SelectionTool',
                                              // "AnnotationEditTool",
                                              // "FormDesignerEditTool",
                                              // "FreeTextAnnotationOption",
                                              // "InkAnnotationOption",
                                              // "ShapeAnnotationOption",
                                              // "StampAnnotation",
                                              // "SignatureOption",
                                              "SearchOption",
                                            ],
                                            // annotationToolbarItems: [
                                            //   "HighlightTool",
                                            //   "UnderlineTool",
                                            //   "StrikethroughTool",
                                            //   "ColorEditTool",
                                            //   "OpacityEditTool",
                                            //   "AnnotationDeleteTool",
                                            //   "StampAnnotationTool",
                                            //   "HandWrittenSignatureTool",
                                            //   "InkAnnotationTool",
                                            //   "ShapeTool",
                                            //   "CalibrateTool",
                                            //   "StrokeColorEditTool",
                                            //   "ThicknessEditTool",
                                            //   "FreeTextAnnotationTool",
                                            //   "FontFamilyAnnotationTool",
                                            //   "FontSizeAnnotationTool",
                                            //   "FontStylesAnnotationTool",
                                            //   "FontAlignAnnotationTool",
                                            //   "FontColorAnnotationTool",
                                            //   "CommentPanelTool",
                                            // ],
                                          }}
                                        >
                                          {/* Inject the required services */}
                                          <Inject
                                            services={[
                                              Toolbar,
                                              Magnification,
                                              Navigation,
                                              Annotation,
                                              LinkAnnotation,
                                              BookmarkView,
                                              ThumbnailView,
                                              Print,
                                              TextSelection,
                                              TextSearch,
                                              FormFields,

                                              //   PageOrganizer,
                                            ]}
                                          />
                                        </PdfViewerComponent>
                                        {startPoint && endPoint && (
                                          <div
                                            style={{
                                              position: "absolute",
                                              left: Math.min(
                                                startPoint.x,
                                                endPoint.x
                                              ),
                                              top: Math.min(
                                                startPoint.y,
                                                endPoint.y
                                              ),
                                              width: Math.abs(
                                                endPoint.x - startPoint.x
                                              ),
                                              height: Math.abs(
                                                endPoint.y - startPoint.y
                                              ),
                                              border: "2px dashed black",
                                              // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            }}
                                          />
                                        )}
                                      </div>
                                      {/* </Worker> */}
                                    </div>
                                  </div>
                                )
                            )}

                            <div className="w-[20%]">
                              <Accordion
                                tabs={tabs}
                                bookmarks={bookmarks}
                                jumpToPage={jumpToPage}
                                numPages={numPages}
                                multiSearchResult={multiSearchResult}
                                addTab={addTab}
                                activeTab={activeTab}
                                viewerRefs={viewerRefs}
                                customsearch={customsearch}
                                // search={search}
                                // setTargetPages={setTargetPages}
                              />
                            </div>
                          </div>
                          {/* Footer */}
                        </div>
                      </div>
                    )}
                    {/* End Tab Modal */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        onClick={openbookModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <FaBook />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Book_Detail")}
                      </span>
                    </div>
                    {isbookOpen && (
                      <div className="fixed inset-0 flex items-center justify-center z-[199]">
                        {/* Background overlay */}
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                        {/* Modal dialog */}
                        <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                          {/* Header */}
                          <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                            <h2 className="text-lg font-semibold">
                              {t("Book_Detail")}
                            </h2>
                            <button
                              onClick={closebookModal}
                              className=" hover:text-gray-900"
                            >
                              <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Content */}
                          <div className="flex w-full p-4 h-[300px]">
                            <div className="w-[30%]">
                              {currentbook &&
                              currentbook.images != null &&
                              currentbook.images.length > 0 ? (
                                <img
                                  src={currentbook.images[0]}
                                  alt="Book Cover"
                                />
                              ) : (
                                <img
                                  src="../images/book.png"
                                  alt="Default Book Cover"
                                />
                              )}
                            </div>
                            <div className="w-[70%]">
                              <div className="max-w-md mx-auto  bg-white rounded-lg shadow-md overflow-hidden">
                                {/* Tab List */}
                                <Tabs>
                                  <TabList className="flex p-4 space-x-2 bg-gray-100">
                                    <Tab
                                      className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                        selectedTab === 0
                                          ? "border-b-2 border-red-500"
                                          : ""
                                      }`}
                                      onClick={() => setSelectedTab(0)}
                                    >
                                      {t("Otzar_HaChochma")}
                                    </Tab>
                                    <Tab
                                      className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                        selectedTab === 1
                                          ? "border-b-2 border-red-500"
                                          : ""
                                      }`}
                                      onClick={() => setSelectedTab(1)}
                                    >
                                      {t("Bibliographic_Data")}
                                    </Tab>
                                    <Tab
                                      className={`py-2 px-4 bg-white rounded-t-lg shadow-md text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-200 focus:outline-none ${
                                        selectedTab === 2
                                          ? "border-b-2 border-red-500"
                                          : ""
                                      }`}
                                      onClick={() => setSelectedTab(2)}
                                    >
                                      {t("National_Library_of_Israel")}
                                    </Tab>
                                  </TabList>

                                  {/* Tab Panels */}
                                  <TabPanel
                                    className={`p-4 ${
                                      selectedTab === 0 ? "" : "hidden"
                                    } overflow-auto h-full`}
                                  >
                                    <div>
                                      <ul>
                                        <li className="p-4 border-b border-gray-600">
                                          {t("book_name")}:{" "}
                                          {htmlToPlainText(currentbook.title) ??
                                            " "}
                                        </li>
                                        <li className="p-4 border-b border-gray-600">
                                          {t("arthor_name")}:{" "}
                                          {htmlToPlainText(
                                            currentbook.auther
                                          ) ?? " "}
                                        </li>
                                      </ul>
                                    </div>
                                  </TabPanel>
                                  <TabPanel
                                    className={`p-4 ${
                                      selectedTab === 1 ? "" : "hidden"
                                    } overflow-auto h-full`}
                                  >
                                    <ul>
                                      <li className="p-4 border-b border-gray-600">
                                        {t("book_name")}:{" "}
                                        {htmlToPlainText(currentbook.title) ??
                                          " "}
                                      </li>
                                      <li className="p-4 border-b border-gray-600">
                                        {t("arthor_name")}:{" "}
                                        {htmlToPlainText(currentbook.auther) ??
                                          " "}
                                      </li>
                                    </ul>
                                  </TabPanel>
                                  <TabPanel
                                    className={`p-4 ${
                                      selectedTab === 2 ? "" : "hidden"
                                    } overflow-auto h-full`}
                                  >
                                    <ul>
                                      <li className="p-4 border-b border-gray-600">
                                        {t("book_name")}:{" "}
                                        {htmlToPlainText(currentbook.title) ??
                                          " "}
                                      </li>
                                      <li className="p-4 border-b border-gray-600">
                                        {t("arthor_name")}:{" "}
                                        {htmlToPlainText(currentbook.auther) ??
                                          " "}
                                      </li>
                                    </ul>
                                  </TabPanel>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                          {/* content */}
                        </div>
                      </div>
                    )}

                    {/* End button */}
                    {/* start buton */}
                    <div class="group relative flex w-full justify-center">
                      <button
                        onClick={openheadModal}
                        className="mb-2 p-2 hover:bg-gray-300  rounded-md"
                      >
                        <BsCardHeading />
                      </button>
                      <span class="absolute z-[199] top-0 left-12 w-max z-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                        {t("Show_Page_Headings")}
                      </span>
                    </div>
                    {isheadOpen && (
                      <div className="fixed inset-0 flex items-center justify-center z-[199]">
                        {/* Background overlay */}
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                        {/* Modal dialog */}
                        <div className="relative bg-white w-[50%]  rounded-lg shadow-lg  pt-0">
                          {/* Header */}
                          <div className="flex bg-[#E9E9E9] justify-between p-1 items-center ">
                            <h2 className="text-lg font-semibold">
                              {t("Page_headings")}
                            </h2>
                            <button
                              onClick={closeheadModal}
                              className=" hover:text-gray-900"
                            >
                              <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Content */}
                          <div className="flex w-full p-4 h-[300px]">
                            <ul
                              dir="rtl"
                              className="overflow-auto h-[280px] m-auto"
                            >
                              {bookmarks.map((bookmark, index) => (
                                <li key={index} className=" hover:bg-gray-300">
                                  <button
                                    onClick={() => {
                                      jumpToPage(
                                        bookmark.pageNum - 1
                                        // bookmark.pageIndex
                                      );
                                      setIsheadOpen(false);
                                    }}
                                  >
                                    {htmlToPlainText(bookmark.index)}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* content */}
                        </div>
                      </div>
                    )}
                    {/* End button */}
                  </div>
                </div>
              </div>
              {tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <div key={tab.id} className="w-[75%]">
                      <div className="flex items-center justify-center h-[82vh]">
                        {/*second pdfviewer */}

                        <div
                          ref={pdfViewerRef}
                          style={{
                            position: "relative",
                            width: "100vw",
                            height: "82vh",
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                        >
                          <div className="flex items-center space-x-2">
                            {" "}
                            {/* Flex container */}
                            <input
                              dir="rtl"
                              className="flex-grow penn rounded-full focus-visible:outline-none py-1 px-2 shadow-sm sm:text-sm"
                              placeholder={t("search_in_book")}
                              type="text"
                              value={currentKeyword.keyword}
                              onChange={(e) => {
                                setCurrentKeyword({
                                  keyword: e.target.value,
                                  matchCase: currentKeyword.matchCase,
                                  wholeWords: currentKeyword.wholeWords,
                                });
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  currentKeyword.keyword
                                ) {
                                  initiateTextSearch(currentKeyword);
                                }
                              }}
                            />
                            <div className="px-2 py-1 rounded-full text-sm text-white bg-[#6D3815]">
                              <button
                                onClick={() =>
                                  initiateTextSearch({
                                    keyword: currentKeyword.keyword,
                                    matchCase: !currentKeyword.matchCase,
                                    wholeWords: currentKeyword.wholeWords,
                                  })
                                }
                              >
                                {t("search")}
                              </button>
                            </div>
                            <div className="group relative flex justify-center">
                              <button onClick={previousTextSearch}>
                                <PreviousIcon />
                              </button>
                              <span className="absolute top-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                {t("Previous")}
                              </span>
                            </div>
                            <div className="group relative flex justify-center">
                              <button onClick={nextTextSearch}>
                                <NextIcon />
                              </button>
                              <span className="absolute top-10 scale-0 transition-all rounded bg-black p-2 text-s text-white group-hover:scale-100">
                                {t("Next")}
                              </span>
                            </div>
                          </div>

                          <PdfViewerComponent
                            id={`pdf-viewer-${tab.id}`}
                            ref={(ref) => (viewerRefs.current[tab.id] = ref)}
                            // documentPath={`https://oizvehadar.com/api/serve_pdf.php?file=${loadedDocs[tab.id] || ""}`}

                            //         resourceUrl = "https://cdn.syncfusion.com/ej2/27.1.52/dist/ej2-pdfviewer-lib"

                            documentPath={loadedDocs[tab.id] || ""}
                            serviceUrl="https://oizvehadar.com/pdfsync1/pdfviewer"
                            ajaxRequestFailed={handleAjaxRequestFailed}
                            height="80vh"
                            pageRenderInitiate={pageRenderInitiate}
                            pageRenderComplete={pageRenderComplete}
                            showNotificationDialog={false}
                            textSearchComplete={() => handletext}
                            // height="640px"
                            initialRenderPages={50}
                            ajaxRequestSuccess={handleAjaxRequestSuccess}
                            enableBookmark={true}
                            enableTextSearch={true}
                            documentLoad={() => {
                              restoreViewerState(tab.id);
                              count();
                            }}
                            toolbarSettings={{
                              showTooltip: true,
                              toolbarItems: [
                                "PageNavigationTool",
                                "MagnificationTool",
                                //   'PanTool',
                                //   'SelectionTool',
                                // "AnnotationEditTool",
                                // "FormDesignerEditTool",
                                // "FreeTextAnnotationOption",
                                // "InkAnnotationOption",
                                // "ShapeAnnotationOption",
                                // "StampAnnotation",
                                // "SignatureOption",
                                // "SearchOption",
                              ],
                              // annotationToolbarItems: [
                              //   "HighlightTool",
                              //   "UnderlineTool",
                              //   "StrikethroughTool",
                              //   "ColorEditTool",
                              //   "OpacityEditTool",
                              //   "AnnotationDeleteTool",
                              //   "StampAnnotationTool",
                              //   "HandWrittenSignatureTool",
                              //   "InkAnnotationTool",
                              //   "ShapeTool",
                              //   "CalibrateTool",
                              //   "StrokeColorEditTool",
                              //   "ThicknessEditTool",
                              //   "FreeTextAnnotationTool",
                              //   "FontFamilyAnnotationTool",
                              //   "FontSizeAnnotationTool",
                              //   "FontStylesAnnotationTool",
                              //   "FontAlignAnnotationTool",
                              //   "FontColorAnnotationTool",
                              //   "CommentPanelTool",
                              // ],
                            }}
                          >
                            {/* Inject the required services */}
                            <Inject
                              services={[
                                Toolbar,
                                Magnification,
                                Navigation,
                                Annotation,
                                LinkAnnotation,
                                BookmarkView,
                                ThumbnailView,
                                Print,
                                TextSelection,
                                TextSearch,
                                FormFields,

                                //   PageOrganizer,
                              ]}
                            />
                          </PdfViewerComponent>

                          {startPoint && endPoint && (
                            <div
                              style={{
                                position: "absolute",
                                left: Math.min(startPoint.x, endPoint.x),
                                top: Math.min(startPoint.y, endPoint.y),
                                width: Math.abs(endPoint.x - startPoint.x),
                                height: Math.abs(endPoint.y - startPoint.y),
                                border: "2px dashed black",
                                // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}

              <div className="w-[20%]">
                <Accordion
                  bookmarks={bookmarks}
                  jumpToPage={jumpToPage}
                  numPages={numPages}
                  multiSearchResult={multiSearchResult}
                  addTab={addTab}
                  customsearch={customsearch}
                  activeTab={activeTab}
                  viewerRefs={viewerRefs}
                  tabs={tabs}
                  // search={search}
                  // setTargetPages={setTargetPages}
                />
              </div>
            </div>
          </TabPanel>
        ))}
      </Tabs>
      {/* Start Tab Modal */}
      {istabOpen && (
        <div className="fixed  inset-0 flex items-center justify-center z-[199]">
          {/* Background overlay */}
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

          {/* Modal dialog */}
          <div className="relative w-[80%] overflow-auto h-[80%] bg-white w-1/2 rounded-lg shadow-lg  pt-0">
            {/* Header */}
            <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
              <h2 className="text-lg font-semibold">{t("Open_Book")}</h2>
              <button
                onClick={closeTabModal}
                className="text-gray-700 hover:text-gray-900"
              >
                <svg
                  className="h-6 w-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div dir="rtl" className="modal-books p-8">
              <h1 className="text-[21px]"> {t("Open_book_in_a_new_tab")}</h1>
              <p className="text-[17px]">{t("search_for_book_list")}</p>
              <div className="tab_recent_book">
                <h3 className="text-red-600 mt-2"> {t("Recent_books")}</h3>
                <div class="flex flex-col  w-[800px] bg-white m-auto p-auto">
                  <div class="flex w-[800px] overflow-x-auto pb-10 ">
                    <div class="flex flex-nowrap ">
                      {/* <div> */}
                      {recenttab.length === 0 ? (
                        <p>{t("No_recent_books_found")}</p>
                      ) : (
                        recenttab.map((book, index) => (
                          <div
                            key={index}
                            className="p-4 ml-2 inline-block bg-white rounded shadow w-[200px] h-full"
                            onClick={() => {
                              addTab(book);
                              setItabOpen(false);
                            }}
                          >
                            <div className="relative aspect-square overflow-hidden rounded-xl">
                              <img
                                src={
                                  book.images[0] || "https://placehold.co/200"
                                }
                                className="h-[200px] w-[200px] object-cover transition hover:scale-110"
                                alt={
                                  htmlToPlainText(book.title) || "Placeholder"
                                }
                              />
                            </div>
                            <div className="flex items-center gap-1 font-semibold">
                              {htmlToPlainText(book.title) || "Book Name"}
                            </div>
                            <div className="font-light text-neutral-500">
                              {htmlToPlainText(book.auther) || "Author Name"}
                            </div>
                          </div>
                        ))
                      )}
                      {/* </div> */}
                      {/* book data */}
                      {/* <div className="p-4 ml-2 inline-block bg-white rounded shadow w-[200px] h-full">
      <div className="relative aspect-square overflow-hidden rounded-xl">
        <img src="https://placehold.co/200" className="h-[200px] w-[200px] object-cover transition hover:scale-110" alt="Placeholder" />
        
      </div>
      <div className="flex items-center gap-1 font-semibold">Book Name</div>
      <div className="font-light text-neutral-500">Author Name</div>
    </div> */}
                      {/* book data */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Fav books */}
              {/* <div className="tab_recent_book">
                   <h3 className="text-red-600 mt-2">Favorite books</h3>
<div class="flex flex-col  w-[800px] bg-white m-auto p-auto">
<div
        class="flex w-[800px] overflow-x-auto pb-10 "
      >
        <div
          class="flex flex-nowrap "
        > */}
              {/* book data */}
              {/* <div className="p-4 ml-2 inline-block bg-white rounded shadow w-[200px] h-full">
      <div className="relative aspect-square overflow-hidden rounded-xl">
        <img src="https://placehold.co/200" className="h-[200px] w-[200px] object-cover transition hover:scale-110" alt="Placeholder" />
        
      </div>
      <div className="flex items-center gap-1 font-semibold">Book Name</div>
      <div className="font-light text-neutral-500">Author Name</div>
    </div> */}
              {/* book data */}
              {/* </div>
      </div>
</div> */}

              {/* </div> */}
            </div>
            {/* Footer */}
          </div>
        </div>
      )}
      {/* End Tab Modal */}
    </div>
  );
};

export default Section2;
const Accordion = ({
  bookmarks,
  jumpToPage,
  numPages,
  multiSearchResult,
  addTab,
  activeTab,
  viewerRefs,
  tabs,
  customsearch,
}) => {
  const [activeAccordion1, setActiveAccordion1] = useState(true);
  const [activeAccordion2, setActiveAccordion2] = useState(true);
  const [activeAccordion3, setActiveAccordion3] = useState(false);
  const [activeAccordion4, setActiveAccordion4] = useState(true);
  const [activeAccordion5, setActiveAccordion5] = useState(false);

  const toggleAccordion = (accordionId) => {
    switch (accordionId) {
      case "accordion-open-body-1":
        setActiveAccordion1(!activeAccordion1);
        break;
      case "accordion-open-body-2":
        setActiveAccordion2(!activeAccordion2);
        break;
      case "accordion-open-body-3":
        setActiveAccordion3(!activeAccordion3);
        break;
      case "accordion-open-body-4":
        setActiveAccordion4(!activeAccordion4);
        break;
        case "accordion-open-body-5":
        setActiveAccordion5(!activeAccordion5);
        break;
      default:
        break;
    }
  };
  var [pages, setPages] = useState([0]);
  useEffect(() => {
    if (numPages > 0) {
      const pageNumbers = Array.from(Array(numPages).keys()).map((i) => i + 1);
      setPages(pageNumbers); // Set the pages state with the array of page numbers
    }
  }, [numPages]);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const { t } = useTranslation();

  return (
    <div id="accordion-open" data-accordion="open" dir="rtl">
      {/* Accordian 4*/}

      {multiSearchResult && Object.keys(multiSearchResult).length > 0 && (
        <div className="block">
          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
              activeAccordion4 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-4"
            aria-expanded={activeAccordion4}
            onClick={() => toggleAccordion("accordion-open-body-4")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("Search_result")}</span>
              <IoMdArrowDropup
                className={`w-5 h-5 me-2 shrink-0 transform ${
                  activeAccordion4 ? "rotate-0" : "rotate-180"
                }`}
              />
            </span>
          </button>
          <div
            id="accordion-open-body-1"
            className={`p-2 bg-white h-[200px] ${
              activeAccordion4 ? "block" : "hidden"
            }`}
            aria-labelledby="accordion-open-heading-4"
          >
            <div className="text-gray-500">
              <div
                className="max-w-xs mx-auto max-h-[200px] overflow-y-auto mt-2"
                dir="ltr"
              >
                <ul className="grid grid-cols-4 p-2 gap-4">
                  {multiSearchResult.pages.map((val, index) => (
                    <li key={index} className=" hover:bg-gray-300">
                      <button
                        onClick={() => {
                          console.log(multiSearchResult);
                          const alreadyOpen = tabs.find(
                            (tab) => multiSearchResult.id === tab.id
                          );

                          // If the tab is already open, no need for timeout, else 3000ms delay
                          let timeout = alreadyOpen ? 0 : 1;
                          addTab(multiSearchResult);
                          console.log(multiSearchResult);
                          console.log("page:", val.page);
                          console.log("text:", multiSearchResult.text);
                          //var timeout;
                          // if(allreadyopen){
                          //   timeout=0;
                          // }else {3000}
                          setTimeout(() => {
                            const viewerInstance =
                              viewerRefs.current[activeTab];
                            if (viewerInstance) {
                              // Create a promise to wait for the document to load
                              const waitForDocumentLoad = new Promise(
                                (resolve, reject) => {
                                  viewerInstance.documentLoad = () => {
                                    resolve(); // Resolve when document is loaded
                                  };
                                }
                              );

                              // Wait for the document to be loaded, then go to the specific page
                              waitForDocumentLoad
                                .then(() => {
                                  console.log(
                                    "Document loaded, navigating to page:",
                                    val.page
                                  );
                                  if (val.page) {
                                    viewerInstance.navigation.goToPage(
                                      val.page
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error while waiting for document to load:",
                                    error
                                  );
                                });
                              viewerInstance.navigation.goToPage(val.page);
                            }
                          }, timeout);
                        }}
                      >
                        <li className="bg-gray-200 p-3 m-auto rounded-full hover:bg-[#6D3815] hover:text-white shadow-md">
                          {" "}
                          {val.page}{" "}
                        </li>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Accordion 1 */}
      <div>
        <button
          type="button"
          className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
            activeAccordion1 ? "bg-[#f7f7f7]" : ""
          }`}
          data-accordion-target="#accordion-open-body-1"
          aria-expanded={activeAccordion1}
          onClick={() => toggleAccordion("accordion-open-body-1")}
        >
          <span className="flex items-center justify-between w-full">
            <span className="flex items-center">{t("Pages")}</span>
            <IoMdArrowDropup
              className={`w-5 h-5 me-2 shrink-0 transform ${
                activeAccordion1 ? "rotate-0" : "rotate-180"
              }`}
            />
          </span>
        </button>
        <div
          id="accordion-open-body-1"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion1 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-1"
        >
          <div className="text-gray-500">
            <div className="relative">
              <input
                type="search"
                id="Searchbooks"
                placeholder={t("Search_in_pages")}
                dir="rtl"
                className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
              />

              <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                <button
                  type="button"
                  className="text-black  hover:text-gray-700"
                >
                  <span className="sr-only">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul
                className="flex flex-col h-[160px] pt-2 pr-2 space-y-1"
                dir="rtl"
              >
                {pages.map((page) => (
                  <li key={page} className=" hover:bg-gray-300">
                    <a
                      href="#"
                      className="block px-3  text-black text-[16px] w-full"
                      onClick={() => jumpToPage(page - 1)}
                    >
                      {page}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
  {/* Accordion 5 */}
  <div>
  <div className="flex border-b-0 border-[#dedede] border  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-1">
  <button className="mb-2 p-2 hover:bg-gray-300  rounded-md">
                        <MdPrint />
                      </button>
        <button
          type="button"
          className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
            activeAccordion5 ? "bg-[#f7f7f7]" : ""
          }`}
          data-accordion-target="#accordion-open-body-5"
          aria-expanded={activeAccordion5}
          onClick={() => toggleAccordion("accordion-open-body-5")}
        >
          <span className="flex items-center justify-between w-full">
            <span className="flex items-center">{t("printables")}</span>
            <IoMdArrowDropup
              className={`w-5 h-5 me-2 shrink-0 transform ${
                activeAccordion5 ? "rotate-0" : "rotate-180"
              }`}
            />
          </span>
          
        </button>
       </div>
        <div
          id="accordion-open-body-5"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion5 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-5"
        >
          <div className="text-gray-500">
            
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul
                className="flex flex-col h-[140px] pt-2 pr-2 space-y-1"
                dir="rtl"
              >
                <li className=" hover:bg-gray-300">
                  1
                </li>
               
               
              </ul>
              <button class="bg-[#6D3815] hover:bg-black w-full text-white py-1 px-4 border-b-4 border-black hover:border-black rounded">
  Add to cart
</button>

            </div>
          </div>
          
        </div>
       
      </div>
      {/* Accordion 2 */}
      <div>
        <button
          type="button"
          className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black border border-b-0 border-[#dedede]  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-3 ${
            activeAccordion2 ? "bg-[#f7f7f7]" : ""
          }`}
          data-accordion-target="#accordion-open-body-2"
          aria-expanded={activeAccordion2}
          onClick={() => toggleAccordion("accordion-open-body-2")}
        >
          <span className="flex items-center justify-between w-full">
            <span className="flex items-center">{t("Indexes")}</span>
            <IoMdArrowDropup
              className={`w-5 h-5 me-2 shrink-0 transform ${
                activeAccordion2 ? "rotate-0" : "rotate-180"
              }`}
            />
          </span>
        </button>

        <div
          id="accordion-open-body-2"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion2 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-2"
        >
          <div className="text-gray-500">
            <div className="relative">
              <input
                type="search"
                id="Searchbooks"
                placeholder={t("Search_in_indexes")}
                dir="rtl"
                className="w-full penn focus:outline-none  px-2 pr-5 border-[##00000059] border-b shadow-sm sm:text-sm"
              />

              <span className="absolute right-0 inset-y-0 end-0 grid w-5 place-content-center">
                <button
                  type="button"
                  className="text-black  hover:text-gray-700"
                >
                  <span className="sr-only">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul
                className="flex flex-col h-[160px] pt-2 pr-2 space-y-1"
                dir="rtl"
              >
                {bookmarks.map((bookmark, index) => (
                  <li key={index} className=" text-[10px] hover:bg-gray-300">
                    <button onClick={() => jumpToPage(bookmark.pageNum)}>
                      {bookmark.index}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Accordion 3 */}
      <div>
        <div className="flex border-b-0 border-[#dedede] border  focus:ring-4 focus:ring-gray-200 hover:bg-[#f7f7f7] gap-1">
          <button onClick={openModal} className=" text-[#3b200f]  mr-2 rounded">
            <FaCirclePlus className="" />
          </button>
          <button
            type="button"
            className={`flex items-center justify-between w-full p-2 font-medium rtl:text-right text-black ${
              activeAccordion3 ? "bg-[#f7f7f7]" : ""
            }`}
            data-accordion-target="#accordion-open-body-3"
            aria-expanded={activeAccordion3}
            onClick={() => toggleAccordion("accordion-open-body-3")}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center">{t("My_Indexes")}</span>
              <IoMdArrowDropup
                className={`w-5 h-5 me-2 shrink-0 transform ${
                  activeAccordion3 ? "rotate-0" : "rotate-180"
                }`}
              />
            </span>
          </button>
        </div>
        <div
          id="accordion-open-body-3"
          className={`p-2 bg-white h-[200px] ${
            activeAccordion3 ? "block" : "hidden"
          }`}
          aria-labelledby="accordion-open-heading-3"
        >
          <div className="text-gray-500">
            <div className="max-w-xs mx-auto overflow-y-auto mt-2" dir="ltr">
              <ul className="flex flex-col h-[160px]  pr-2 space-y-1" dir="rtl">
                <li className=" hover:bg-gray-300">
                  <a>{t("My_Indexes")}</a>
                </li>
              </ul>
            </div>
            {/* Modal */}
            {isOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[199]">
                {/* Background overlay */}
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>

                {/* Modal dialog */}
                <div className="relative bg-white w-1/2 rounded-lg shadow-lg  pt-0">
                  {/* Header */}
                  <div className="flex bg-[#E9E9E9] justify-between p-4 pt-1 pb-2 items-center ">
                    <h2 className="text-lg font-semibold">
                      {t("Add_a_new_personal_index_in_the_page")}
                    </h2>
                    <button
                      onClick={closeModal}
                      className="text-gray-700 hover:text-gray-900"
                    >
                      <svg
                        className="h-6 w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.35 5.64a1 1 0 011.42 1.42L11.41 10l4.36 4.36a1 1 0 11-1.42 1.42L10 11.41l-4.36 4.36a1 1 0 11-1.42-1.42L8.59 10 4.22 5.64a1 1 0 111.42-1.42L10 8.59l4.36-4.36z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Content */}
                  <div className=" p-10 w-[50%] m-auto">
                    <input
                      type="text"
                      name="add"
                      placeholder={t("Index_Name")}
                      className="border-black w-full border-b-2 m-auto"
                    />
                  </div>

                  {/* Footer */}
                  <div className="mt-4 bg-[#E9E9E9] p-2 flex justify-center">
                    <button className="px-4 py-2 rounded-full  text-white hover:bg-red-800 bg-[#6D3815] rounded-full focus:outline-none">
                      {t("create")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
