import React, { useState,useEffect,useContext } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { Link } from "react-router-dom";
import { FiX } from 'react-icons/fi'; // FiX icon from react-icons
import { FaCartPlus } from "react-icons/fa6";
import axios from 'axios';
import BASE_URL from './constant';
import { AuthContext } from './context/AuthContext';



export default function AjaxCart() {
  const [products,setProducts] = useState([
  ]);
  const [bundle,setBundles] = useState([
  ]);
  const [total,setTotal] = useState("0.00");
  const {cart, addToCart, removeFromCart, currentUser, getUserData } = useContext(AuthContext);
  useEffect(() => {
    // fetchCart();
  }, []);

  const fetchCart = async () => {
    try {
   const userData = getUserData();
      const response = await axios.get(`${BASE_URL}/api_cart.php?user_id=${userData.id}&details=true`);
      const bookData = response.data;
     console.log(response.data);
     if (Array.isArray(bookData.books)) {
      console.log("yse");
      setProducts(bookData.books);
     }
     if(Array.isArray(bookData.bundles)) {
      console.log("yse1");
      setBundles(bookData.bundles);
     }
     setTotal(bookData.sub_total);
      // if (Array.isArray(bookData)) {
      //   setAllTopics(bookData);
      //   setTopics([bookData[0]]);
      //   setSubscriptionPrice(bookData[0].price);
      //   handleFilterChange(bookData[0]);
       
      // } else {
      //   console.error('Expected an array of books');
      // }
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };
  const [open, setOpen] = useState(false); // Initially closed

  return (
    <>
      <button
        type="button"
        className="bg-red-600 ml-2 text-white px-4 py-2 rounded-md shadow-md hover:bg-black"
        onClick={() => setOpen(true)}
      >
       <FaCartPlus />

      </button>

      <Dialog className="relative z-10 transition duration-500 ease-in" open={open} onClose={() => setOpen(false)}>
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition duration-800 ease-in-out" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-lg font-medium text-gray-900">
                        Shopping cart
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <FiX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <h1>Books:</h1>
                        <br></br>
                        <ul role="list" className="-my-6 divide-y divide-gray-200">
                          {products.map((product) => (
                            <li key={product.id} className="flex py-6">
                              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                  src={product.images[0]}
                                  alt={product.imageAlt}
                                  className="h-full w-full object-cover object-center"
                                />
                              </div>

                              <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                  <div className="flex justify-between text-base font-medium text-gray-900">
                                    <h3>
                                      <a href={product.href}>{product.title}</a>
                                    </h3>
                                    <p className="ml-4">${product.price}</p>
                                  </div>
                                  {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                                </div>
                                {/* <div className="flex flex-1 items-end justify-between text-sm">
                                  <p className="text-gray-500">Qty {product.quantity}</p>

                                  <div className="flex">
                                    <button
                                      type="button"
                                      className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                            </li>
                          ))}
                        </ul>
                        <br></br>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>${total}</p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                    <div className="mt-6">
                      <Link
                        to="/Checkout"
                        onClick={() => setOpen(false)}
                        className="flex items-center justify-center rounded-md border border-transparent bg-[#3b200f] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-[#161210]"
                      >
                        Checkout
                      </Link>
                    </div>
                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                      <p>
                        or{' '}
                        <button
                          type="button"
                          className="font-medium text-red-600 hover:text-red-500"
                          onClick={() => setOpen(false)}
                        >
                          Continue Shopping
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
