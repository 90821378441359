// authService.js
import React, { useState,useContext } from 'react'
import { getAuth,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from 'axios';
import { addDoc, collection,getDocs,deleteDoc,doc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import BASE_URL from '../constant';
import { toast } from 'react-toastify'; 


export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api_users.php`, userData);

    // Check if the response status is within the range 200-299
    if (response.status === 200) {
      console.log('API Response:', response.data);
      return response.data;
    }else if(response.status === 200){
        return response;
    }else if (response.status===205){
      console.log(response.data);
      return response;
     
    } else {
      console.error('API Request failed with status:', response.status);
      // Throw an error to be caught in the catch block
      throw new Error('API Request failed');
    }
  } catch (error) {
    // Check if the error is an Axios error and log accordingly
    if (axios.isAxiosError(error)) {
      console.error('Axios Error:', error.message);
      if (error.response) {
        console.error('Response Data:', error.response.data);
        console.error('Response Status:', error.response.status);
        console.error('Response Headers:', error.response.headers);
      }
    } else {
      console.error('Error making API request:', error.message);
    }
    // Re-throw the error to be caught by the calling function
    throw error;
  }
};

export const saveUserDataToFirestore = async (userData) => {
  try {
    const userDocRef = await addDoc(collection(firestore, 'users'), userData);
    console.log('User data added to Firestore with ID:', userDocRef.id);
  } catch (error) {
    console.error('Error adding user data to Firestore:', error.message);
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api_users.php`, userData);

    // Check if the response status is within the range 200-299
    if (response.status === 200) {
      console.log('API Response:', response.data);
      return response.data;
    } else if (response.status === 205) {
      return response;
    } else {
      console.error('API Request failed with status:', response.status);
      // Throw an error to be caught in the catch block
      throw new Error('API Request failed');
    }
  } catch (error) {
    // Check if the error is an Axios error and log accordingly
    if (axios.isAxiosError(error)) {
      console.error('Axios Error:', error.message);
      if (error.response) {
        console.error('Response Data:', error.response.data);
        console.error('Response Status:', error.response.status);
        console.error('Response Headers:', error.response.headers);
      }
    } else {
      console.error('Error making API request:', error.message);
    }
    // Re-throw the error to be caught by the calling function
    throw error;
  }
};
// export const getCountryFromIP = async () => {
//     try {
//       const response = await fetch('https://ipinfo.io/json');
//       const data = await response.json();
  
//       // Access the country information
//       // const country = data.country;
//       // console.log('Country:', country);
//       const countryNames = {
//         AF: "Afghanistan",
//         AX: "Aland Islands",
//         AL: "Albania",
//         DZ: "Algeria",
//         AS: "American Samoa",
//         AD: "Andorra",
//         AO: "Angola",
//         AI: "Anguilla",
//         AQ: "Antarctica",
//         AG: "Antigua and Barbuda",
//         AR: "Argentina",
//         AM: "Armenia",
//         AW: "Aruba",
//         AU: "Australia",
//         AT: "Austria",
//         AZ: "Azerbaijan",
//         BS: "Bahamas",
//         BH: "Bahrain",
//         BD: "Bangladesh",
//         BB: "Barbados",
//         BY: "Belarus",
//         BE: "Belgium",
//         BZ: "Belize",
//         BJ: "Benin",
//         BM: "Bermuda",
//         BT: "Bhutan",
//         BO: "Bolivia",
//         BA: "Bosnia and Herzegovina",
//         BW: "Botswana",
//         BV: "Bouvet Island",
//         BR: "Brazil",
//         IO: "British Indian Ocean Territory",
//         BN: "Brunei Darussalam",
//         BG: "Bulgaria",
//         BF: "Burkina Faso",
//         BI: "Burundi",
//         KH: "Cambodia",
//         CM: "Cameroon",
//         CA: "Canada",
//         CV: "Cape Verde",
//         KY: "Cayman Islands",
//         CF: "Central African Republic",
//         TD: "Chad",
//         CL: "Chile",
//         CN: "China",
//         CX: "Christmas Island",
//         CC: "Cocos (Keeling) Islands",
//         CO: "Colombia",
//         KM: "Comoros",
//         CG: "Congo",
//         CD: "Congo, Democratic Republic of the",
//         CK: "Cook Islands",
//         CR: "Costa Rica",
//         CI: "Cote d'Ivoire",
//         HR: "Croatia",
//         CU: "Cuba",
//         CY: "Cyprus",
//         CZ: "Czech Republic",
//         DK: "Denmark",
//         DJ: "Djibouti",
//         DM: "Dominica",
//         DO: "Dominican Republic",
//         EC: "Ecuador",
//         EG: "Egypt",
//         SV: "El Salvador",
//         GQ: "Equatorial Guinea",
//         ER: "Eritrea",
//         EE: "Estonia",
//         ET: "Ethiopia",
//         FK: "Falkland Islands (Malvinas)",
//         FO: "Faroe Islands",
//         FJ: "Fiji",
//         FI: "Finland",
//         FR: "France",
//         GF: "French Guiana",
//         PF: "French Polynesia",
//         TF: "French Southern Territories",
//         GA: "Gabon",
//         GM: "Gambia",
//         GE: "Georgia",
//         DE: "Germany",
//         GH: "Ghana",
//         GI: "Gibraltar",
//         GR: "Greece",
//         GL: "Greenland",
//         GD: "Grenada",
//         GP: "Guadeloupe",
//         GU: "Guam",
//         GT: "Guatemala",
//         GG: "Guernsey",
//         GN: "Guinea",
//         GW: "Guinea-Bissau",
//         GY: "Guyana",
//         HT: "Haiti",
//         HM: "Heard Island and McDonald Islands",
//         VA: "Holy See (Vatican City State)",
//         HN: "Honduras",
//         HK: "Hong Kong",
//         HU: "Hungary",
//         IS: "Iceland",
//         IN: "India",
//         ID: "Indonesia",
//         IR: "Iran, Islamic Republic of",
//         IQ: "Iraq",
//         IE: "Ireland",
//         IM: "Isle of Man",
//         IL: "Israel",
//         IT: "Italy",
//         JM: "Jamaica",
//         JP: "Japan",
//         JE: "Jersey",
//         JO: "Jordan",
//         KZ: "Kazakhstan",
//         KE: "Kenya",
//         KI: "Kiribati",
//         KP: "Korea, Democratic People's Republic of",
//         KR: "Korea, Republic of",
//         KW: "Kuwait",
//         KG: "Kyrgyzstan",
//         LA: "Lao People's Democratic Republic",
//         LV: "Latvia",
//         LB: "Lebanon",
//         LS: "Lesotho",
//         LR: "Liberia",
//         LY: "Libyan Arab Jamahiriya",
//         LI: "Liechtenstein",
//         LT: "Lithuania",
//         LU: "Luxembourg",
//         MO: "Macao",
//         MK: "Macedonia, the Former Yugoslav Republic of",
//         MG: "Madagascar",
//         MW: "Malawi",
//         MY: "Malaysia",
//         MV: "Maldives",
//         ML: "Mali",
//         MT: "Malta",
//         MH: "Marshall Islands",
//         MQ: "Martinique",
//         MR: "Mauritania",
//         MU: "Mauritius",
//         YT: "Mayotte",
//         MX: "Mexico",
//         FM: "Micronesia, Federated States of",
//         MD: "Moldova, Republic of",
//         MC: "Monaco",
//         MN: "Mongolia",
//         ME: "Montenegro",
//         MS: "Montserrat",
//         MA: "Morocco",
//         MZ: "Mozambique",
//         MM: "Myanmar",
//         NA: "Namibia",
//         NR: "Nauru",
//         NP: "Nepal",
//         NL: "Netherlands",
//         AN: "Netherlands Antilles",
//         NC: "New Caledonia",
//         NZ: "New Zealand",
//         NI: "Nicaragua",
//         NE: "Niger",
//         NG: "Nigeria",
//         NU: "Niue",
//         NF: "Norfolk Island",
//         MP: "Northern Mariana Islands",
//         NO: "Norway",
//         OM: "Oman",
//         PK: "Pakistan",
//         PW: "Palau",
//         PS: "Palestinian Territory, Occupied",
//         PA: "Panama",
//         PG: "Papua New Guinea",
//         PY: "Paraguay",
//         PE: "Peru",
//         PH: "Philippines",
//         PN: "Pitcairn",
//         PL: "Poland",
//         PT: "Portugal",
//         PR: "Puerto Rico",
//         QA: "Qatar",
//         RE: "Reunion",
//         RO: "Romania",
//         RU: "Russian Federation",
//         RW: "Rwanda",
//         BL: "Saint Barthelemy",
//         SH: "Saint Helena",
//         KN: "Saint Kitts and Nevis",
//         LC: "Saint Lucia",
//         MF: "Saint Martin",
//         PM: "Saint Pierre and Miquelon",
//         VC: "Saint Vincent and the Grenadines",
//         WS: "Samoa",
//         SM: "San Marino",
//         ST: "Sao Tome and Principe",
//         SA: "Saudi Arabia",
//         SN: "Senegal",
//         RS: "Serbia",
//         SC: "Seychelles",
//         SL: "Sierra Leone",
//         SG: "Singapore",
//         SK: "Slovakia",
//         SI: "Slovenia",
//         SB: "Solomon Islands",
//         SO: "Somalia",
//         ZA: "South Africa",
//         GS: "South Georgia and the South Sandwich Islands",
//         ES: "Spain",
//         LK: "Sri Lanka",
//         SD: "Sudan",
//         SR: "Suriname",
//         SJ: "Svalbard and Jan Mayen",
//         SZ: "Swaziland",
//         SE: "Sweden",
//         CH: "Switzerland",
//         SY: "Syrian Arab Republic",
//         TW: "Taiwan, Province of China",
//         TJ: "Tajikistan",
//         TZ: "Tanzania, United Republic of",
//         TH: "Thailand",
//         TL: "Timor-Leste",
//         TG: "Togo",
//         TK: "Tokelau",
//         TO: "Tonga",
//         TT: "Trinidad and Tobago",
//         TN: "Tunisia",
//         TR: "Turkey",
//         TM: "Turkmenistan",
//         TC: "Turks and Caicos Islands",
//         TV: "Tuvalu",
//         UG: "Uganda",
//         UA: "Ukraine",
//         AE: "United Arab Emirates",
//         GB: "United Kingdom",
//         US: "United States",
//         UM: "United States Minor Outlying Islands",
//         UY: "Uruguay",
//         UZ: "Uzbekistan",
//         VU: "Vanuatu",
//         VE: "Venezuela",
//         VN: "Viet Nam",
//         VG: "Virgin Islands, British",
//         VI: "Virgin Islands, U.S.",
//         WF: "Wallis and Futuna",
//         EH: "Western Sahara",
//         YE: "Yemen",
//         ZM: "Zambia",
//         ZW: "Zimbabwe"
//     };
    
//     const countryAbbreviation = data.country;
//     const country = countryNames[countryAbbreviation] || "Unknown"; // Default to "Unknown" if abbreviation is not found
//     console.log('Country:', country);
//       return country;
//     } catch (error) {
//       console.error('Error fetching IP information:', error.message);
//     }
//   };
  
  export const handleGoogleLogin = async (navigate, saveUserData) => {
    // Your existing code
    
    try {
      // Your existing code
      
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log('Google login success:', user);
  
    
      const userData = {
        name: user.displayName,
        password: 'social',
        email: user.email,
        contact: '00000000',
        fb_id: user.uid,
       // country: country
      };
  
      registerUser(userData)
        .then((response) => {
          if (response && response.status === 205) {
            console.log('Email already registered:', response.data.message);
            const userData = {
              action:"login",
              password: 'social',
              email: user.email,
            };
            loginUser(userData)
              .then((response) => {
                if (response && response.status === 404) {
                  toast.error('Login failed: User not found');
                } else if(response && response.status === 205){
                  auth.signOut();
                  toast.error('User Already Registered by Email&Pass ');
                }
                else {
                  saveUserData(response.user_details);
                  navigate('/User/User');
                }
              })
              .catch((error) => {
                console.error('Login failed:', error);
                toast.error('Login failed: ' + error.message);
              });
          } else {
            console.log('User registered successfully:', response);
            saveUserData(response.user_details);
            saveUserDataToFirestore({ phone: 'social', email: user.email, name: user.displayName, uid: user.uid });
            navigate('/User/User');
          }
        })
        .catch((error) => {
          console.error('User registration failed:', error);
          if (error.code === 'auth/email-already-in-use') {
            toast.error('Email is already in use. Please try again with another email.');
          } else {
            toast.error('User registration failed. Please try again.');
          }
        });
    } catch (error) {
      console.error('Google login failed:', error.message);
    }
  };
// export const handleGoogleLogin = async (navigate,saveUserData) => {
//     // const { saveUserData, getUserData, emptyCurrentUser } = useContext(AuthContext);
//   const provider = new GoogleAuthProvider();
//   //const navigate = useNavigate();

//   try {
//     const result = await signInWithPopup(auth, provider);
//     const user = result.user;
//     console.log('Google login success:', user);
//  const country =await   getCountryFromIP()
//     const userData = {
//       name: user.displayName,
//       password: 'social',
//       email: user.email,
//       phone: '00000000',
//       fb_id: user.uid,
//       country:country
//     };

//     registerUser(userData)
//       .then((response) => {
//         if (response && response.status === 205) {
//           console.log('Email already registered:', response.data.message);
//           const userData = {
//             password: 'social',
//             email: user.email,
//           };
//           loginUser(userData)
//             .then((response) => {
//                 if (response && response.status === 404) {
//                     alert('Login failed:',"User not found");
//                 }else{

//                     saveUserData(response.user_details);
//                     navigate('/Profile/Profile');
                    

//                 }
             
//             })
//             .catch((error) => {
//               console.error('Login failed:', error);
//               alert('Login failed:', error);
//             });
//         } else {
//           console.log('User registered successfully:', response);
//           saveUserData(response.user_details);
//           saveUserDataToFirestore({ phone: 'social', email: user.email, name: user.displayName ,uid:user.uid});
//           navigate('/Profile/Profile');
//         }
//       })
//       .catch((error) => {
//         console.error('User registration failed:', error);
//         alert('User registration failed:', error);
//       });
//   } catch (error) {
//     console.error('Google login failed:', error.message);
//   }
// };

export const addDataToFirestore = async (email) => {
  try {
    // Add data to a collection named 'exampleCollection'
    const docRef =  await addDoc(collection(firestore,'rest-pass'), {
      email: email,
      password_reset: true,
      // Add more key-value pairs as needed
    });

    console.log('Document added with ID: ', docRef.id);
  } catch (error) {
    console.error('Error adding document: ', error);
  }
};
export const retrieveDataFromFirestore = async () => {
  try {
    // Get data from the 'exampleCollection'
    const querySnapshot = await getDocs(collection(firestore,'rest-pass'));

    const data = [];

    // Iterate through the documents in the collection
    querySnapshot.forEach((doc) => {
      // Add each document's data to the array
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    console.log('Retrieved Data: ', data);

    // Return the array of retrieved data
    return data;
  } catch (error) {
    console.error('Error retrieving documents: ', error);
  }
};

export const  updateUserData = async (email,fb_id,password,doc_id) => {
  console.log({email,fb_id,password,doc_id});
  try {
    const response = await axios.post(
      `${BASE_URL}/user_api.php/?email=${email}&fb_id=${fb_id}`,
       {password:password},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Response:', response.data);
    deleteDocumentByEmail(email);
    // return "done";
  } catch (error) {
    console.error('Error updating user data:', error.message);
  }
};
// export const  verifyUser = async (email) => {
//   console.log({email});
//   try {
//     const response = await axios.post(
//       `${BASE_URL}/user_api.php/?email=${email}`,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     console.log('Response:', response.data);
   
//     // return "done";
//   } catch (error) {
//     console.error('Error updating user data:', error.message);
//   }
// };


export const deleteDocumentByEmail = async (emailToDelete) => {
  try {
    // Get data from the 'rest-pass' collection
    const querySnapshot = await getDocs(collection(firestore, 'rest-pass'));

    // Iterate through the documents in the collection
    querySnapshot.forEach(async (doc) => {
      // Check if the document contains the specified email
      if (doc.data().email === emailToDelete) {
        // Delete the document
        await deleteDoc(doc.ref);
        console.log(`Document with email ${emailToDelete} deleted successfully.`);
      }
    });
  } catch (error) {
    console.error('Error deleting document: ', error);
    throw error; // Rethrow the error to handle it at the caller's level
  }
};
export const signOut = async (navigate) => {
    try {
      await auth.signOut();
     
      navigate('/Login');
    //   setTimeout(() => {
    //     emptyCurrentUser();
    //  }, 2000);
     
    } catch (error) {
      console.error(error);
    }
  };
