
import React, { useState, useContext, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";

const Phistory = () => {
  const { getUserData } = useContext(AuthContext);
  
  // State for storing book history and order details
  const [bookHistory, setBookHistory] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null); // For storing specific order details
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [viewDetailsOrderId, setViewDetailsOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userdata = getUserData();
    GetOrder(userdata);
  }, [getUserData]);

  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?user_id=${orderData.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order successfully fetched:', response.data);
      setBookHistory(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setOrderDetails(response.data); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const openModal = (orderId) => {
    setViewDetailsOrderId(orderId);
    fetchOrderDetails(orderId); // Fetch the order details
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOrderDetails(null); // Clear order details when closing the modal
  };

  // Translation hook
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold mb-4">{t('phistory')}</h2>

      {/* Table */}
      <div className="overflow-x-auto max-h-[400px]">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('order_no')}</th>
              <th className={`py-2 px-4 ${getTextAlignmentClass()}`} >
                {t('items')}
              </th>
              <th className={`py-2 px-4 ${getTextAlignmentClass()}`} >
                {t('price')} 
              </th>
              <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('purchase_date')}</th>
              <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('action')}</th>
            </tr>
          </thead>
          <tbody>
          {bookHistory && bookHistory.length > 0 ? (
  bookHistory.map((order) => (
    <tr key={order.id}>
      <td className="py-2 px-4">{order.id}</td>
      <td className="py-2 px-4">{order.order_details[0]?.message}</td>
      <td className="py-2 px-4">${order.total_price}</td>
      <td className="py-2 px-4">{order.purchaseDate}</td>
      <td className="py-2 px-4">
        <button onClick={() => openModal(order.id)} className="px-2 py-1 m-2 text-white rounded-lg shadow bg-[#3b200f]">
          {t('view_details')}
        </button>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="5" className="text-center py-4">
      No Activity Found
    </td>
  </tr>
)}

          </tbody>
        </table>
      </div>

      {/* Modal for displaying order details */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-8 max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4">Order Details</h2>
            {orderDetails ? (
              <div>
                <p>Order Number: {orderDetails.order.id}</p>
                <p>Items: </p>
                <ul>
            {orderDetails.order_details && orderDetails.order_details.length > 0 ? ( // Check if books exists and has items
              orderDetails.order_details.map((book) => (
                <li key={book.id}>{book.message} - ${book.price}</li>
              ))
            ) : (
              <li>No items found in this order.</li> // Fallback if no books are found
            )}
          </ul>
           
                <p>Total Price: ${orderDetails.order.total_price}</p>
                <p>Purchase Date: {orderDetails.order.purchaseDate}</p>
              </div>
            ) : (
              <p>Loading order details...</p>
            )}
            <button onClick={closeModal} className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Phistory;
