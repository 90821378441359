import React, { useState,useContext,useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { FaBookReader } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; 
import { AuthContext } from "../context/AuthContext";
import axios from 'axios';
import BASE_URL from '../constant';
import htmlToPlainText from "../htmltotext";
import { useNavigate} from "react-router-dom";
const MyFavorite = () => {
  const history = useNavigate();
  const { getUserData } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("purchased");

  // Sample data for purchased and rented books
  const [slideData, setSlideData] = useState([]);
  useEffect(() => {
    const userdata = getUserData();
    fetchBooks(userdata);
}, []);

const fetchBooks = async (userdata) => {
    try {
        let url = `${BASE_URL}/api_book.php/favoritebooks?user_id=${userdata.id}`;
        
        const response = await axios.get(url);
        const bookData = response.data;
        if (Array.isArray(bookData)) {
          setSlideData(bookData);
            // extractTopics(bookData);
        } else {
            console.error('Expected an array of books');
        }
    } catch (error) {
        console.error('Error fetching books:', error);
    }
};
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        {/* <h2 className="text-2xl font-bold">{t('my_books')}</h2> */}
      </div>

      <div className="mb-8">
        <div className="flex">
          {/* <button
            className={`mr-4 focus:outline-none ${activeTab === "purchased" ? "text-blue-600 text-xl border-b-2 border-blue-600 " : "text-l text-gray-500"}`}
            onClick={() => handleTabClick("purchased")}
          >
         {t('purchased_books')}
          </button> */}
         
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8">
        {/* Purchased Books */}
        {activeTab === "purchased" && (
          <div>
            <h3 className="text-xl font-semibold mb-4"> Favorite Books</h3>
            <div className="overflow-x-auto max-h-[400px]">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200 text-left">
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('name')}</th>
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('author')}</th>
                  {/* <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('category')}</th> */}
                  <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {slideData.map((book) => (
                  <tr key={book.id} className="border-b border-black-800">
                    <td className="py-2 px-4">{htmlToPlainText(book.book_name)}</td>
                    <td className="py-2 px-4">{htmlToPlainText(book.author)}</td>
                    {/* <td className="py-2 px-4">{book.category}</td> */}
                    <td className="py-2 px-4 flex">
                     
                    <button className="flex ml-2 mr-2  items-center bg-green-500 text-white px-3 py-1 rounded"onClick={() =>{
                      history(`/pdfViewer/${book.book_id}/${htmlToPlainText(book.book_name)}`);
                    }}>
                        <FaBookReader  className="mr-1" />
                         {t('read_books')}
                      </button>
                      {/* <button className="flex ml-2 mr-2 items-center bg-blue-500 text-white px-3 py-1 rounded">
                        <FiDownload className="mr-1" />
                         {t('download')}
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        )}

       
      </div>
    </>
  );
};

export default MyFavorite;
