import React, { useEffect, useContext, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../constant';
import { AuthContext } from "../context/AuthContext";

const ThankYouPage = () => {
  const { pro_id,price, type, paymentConfirmed } = useParams();
  const location = useLocation();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const { getUserData } = useContext(AuthContext);
  let call =0;

  useEffect( () => {
    // Extract query parameters
    call=1;
    const queryParams = new URLSearchParams(location.search);
    const paymentIntent = queryParams.get('payment_intent');
    const clientSecret = queryParams.get('payment_intent_client_secret');
    const redirectStatus = queryParams.get('redirect_status');

    // Ensure paymentIntent is available and order has not been placed yet
    if (call==1) {
      const userdata = getUserData();
      const orderData = {
        user_id: userdata.id,
        total_price: price, // Make sure price is defined or passed down
        transaction_id: paymentIntent,
        order_details: [
          {
            book_id: null,
            volume_id: null,
            subscription_id: type === "subscription" ? pro_id : null,
            bundle_id: type === "bundle" ? pro_id : null,
            price: price, // Ensure price is accessible
          }
        ]
      };

    insertOrder(orderData);
     call=2; // Set flag to true after placing the order
    }
  }, []);

  const insertOrder = async (orderData) => {
    try {
      const response = await axios.post(`${BASE_URL}/api_orders.php`, orderData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order successfully placed:', response.data);
      return response.data; // You can return the response for further processing
    } catch (error) {
      console.error('Error placing order:', error);
      throw error; // Re-throw if you want to handle it higher up
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg text-center">
        {paymentConfirmed ? (
          <>
            <AiOutlineCheckCircle className="text-green-500 mx-auto h-12 w-12" />
            <h2 className="text-2xl font-semibold mt-4">Payment Confirmed!</h2>
            <p className="text-gray-600 mt-2">Thank you for your purchase.</p>
          </>
        ) : (
          <>
            <AiOutlineCheckCircle className="text-gray-400 mx-auto h-12 w-12" />
            <h2 className="text-2xl font-semibold mt-4">Payment Pending</h2>
            <p className="text-gray-600 mt-2">Your payment is being processed.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
