import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';   

const Contact=()=>{
  const {t}=useTranslation();

    return(
        <>
      <div className="flex justify-center items-center mt-10 w-[100%] ">
      <div className=" p-4   w-[100%] flex">
        <div className="w-1/2">
          <img src="https://via.placeholder.com/400" alt="Stock" className="w-full h-auto rounded-md" />
        </div>
        <div className="w-1/2 p-8">
          <h2 className="text-2xl font-semibold mb-4">{t('contact_us')}</h2>
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <FaPhone className="mr-2" />
              <span>123-456-7890</span>
            </div>
            <div className="flex items-center mb-2">
              <FaEnvelope className="mr-2" />
              <span>info@example.com</span>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="mr-2" />
              <span>123 Main St, City, Country</span>
            </div>
          </div>
          <form>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="name">Name</label>
              <input type="text" id="name" className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="email">Email</label>
              <input type="email" id="email" className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block mb-2" htmlFor="message">Message</label>
              <textarea id="message" className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"></textarea>
            </div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Send</button>
          </form>
        </div>
      </div>
    </div>
        </>
    )
};
export default Contact;