import React, { useState, useRef } from "react";
import { FaDownload, FaCopy, FaPrint } from "react-icons/fa";
import html2canvas from "html2canvas";
import { useTranslation } from 'react-i18next'; 

const Notes = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const notesRef = useRef(null);
  const downloadRef = useRef(null);
  const notesData = [
    {
      id: 1,
      noteName: "Note 1",
      savedDate: "2024-06-06",
      bookName: "Book 1",
      authorName: "Author 1"
    },
    {
      id: 2,
      noteName: "Note 2",
      savedDate: "2024-06-07",
      bookName: "Book 2",
      authorName: "Author 2"
    },
    {
      id: 3,
      noteName: "Note 3",
      savedDate: "2024-05-07",
      bookName: "Book 3",
      authorName: "Author 3"
    },
    {
      id: 4,
      noteName: "Note 4",
      savedDate: "2024-04-07",
      bookName: "Book 4",
      authorName: "Author 4"
    },
    // Add more notes as needed
  ];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(`"${text}" copied to clipboard!`);
  };

  const downloadAsImage = (rowData) => {
    const element = document.createElement("div");
    element.innerHTML = `
      <h1>${rowData.noteName}</h1>
      <p><strong>Book Name:</strong> ${rowData.bookName}</p>
      <p><strong>Author Name:</strong> ${rowData.authorName}</p>
      <p><strong>Saved Date:</strong> ${rowData.savedDate}</p>
    `;
    element.style.position = "absolute";
    element.style.left = "-9999px";
    document.body.appendChild(element);

    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "note_details.png";
      link.href = canvas.toDataURL();
      link.click();
      document.body.removeChild(element); // Clean up
    });
  };


  const printNote = (note) => {
    const printContent = `
      <h1>${note.noteName}</h1>
      <p><strong>Book Name:</strong> ${note.bookName}</p>
      <p><strong>Author Name:</strong> ${note.authorName}</p>
      <p><strong>Saved Date:</strong> ${note.savedDate}</p>
    `;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Note Details</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            h1 {
              font-size: 24px;
            }
            p {
              margin-bottom: 10px;
            }
          </style>
        </head>
        <body>
          ${printContent}
          <script>
            window.onload = function() {
              window.print();
              setTimeout(() => {
                window.close();
              }, 100);
            }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };
  const { t, i18n } = useTranslation();
  const getTextAlignmentClass = () => {
    return i18n.language === 'he' ? 'text-right' : 'text-left';
  };

  return (
    <div className="max-w-full p-4 mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
      <input
        type="text"
        placeholder={t('search')}
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="overflow-x-auto max-h-[400px]">
      <table className="w-full table-auto" ref={notesRef}>
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('note_name')}</th>
            <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('saved_date')}</th>
            <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('book_name')}</th>
            <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('author')}</th>
            <th className={`py-2 px-4 ${getTextAlignmentClass()}`}>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {notesData
            .filter((note) =>
              note.noteName.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((note) => (
              <tr key={note.id} className="border-b">
                <td
                  className="px-4 py-2 cursor-pointer"
                  onClick={() => printNote(note)}
                >
                  {note.noteName}
                </td>
                <td className="px-4 py-2">{note.savedDate}</td>
                <td className="px-4 py-2">{note.bookName}</td>
                <td className="px-4 py-2">{note.authorName}</td>
                <td className="px-4 py-2">
                  <button
                    className="bg-blue-500 ml-2 mr-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => downloadAsImage(note)}
                  >
                    <FaDownload /> {t('download')}
                  </button>
                  <button
                    className="bg-green-500 ml-2 mr-2 ml-4 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => copyToClipboard(note.noteName)}
                  >
                    <FaCopy /> {t('copy')}
                  </button>
                  <button
                    className="bg-purple-500 ml-2 mr-2 ml-4 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                  >
                    <FaPrint onClick={() => printNote(note)} /> {t('print')}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Notes;
