// import { createContext, useEffect, useState } from "react";
// import { auth } from "../firebase";
// import { onAuthStateChanged } from "firebase/auth";

// export const AuthContext = createContext();

// export const AuthContextProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState({});

//   useEffect(() => {
//     const unsub = onAuthStateChanged(auth, (user) => {
//       setCurrentUser(user);
//       console.log(user);
//     });

//     return () => {
//       unsub();
//     };
//   }, []);
//   const saveUserData = (userData) => {
//     // Update the current user state
//     setCurrentUser((prevUser) => ({
//       ...prevUser,
//       ...userData,
//     }));

//     // Save user data to local storage
//     localStorage.setItem("userData", JSON.stringify({ ...currentUser, ...userData }));
//   };

//   const getUserData = () => {
//     // Retrieve user data from local storage
//     const storedUserData = localStorage.getItem("userData");
//     return storedUserData ? JSON.parse(storedUserData) : {};
//   };
//   const emptyCurrentUser = () => {
//     // Reset the current user state to an empty object
//     setCurrentUser({});
//     // Optionally, remove user data from local storage
//     localStorage.removeItem("userData");
//   };
//   return (
//     <AuthContext.Provider value={{ currentUser,saveUserData, getUserData, emptyCurrentUser }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import BASE_URL from "../constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [cart, setCart] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      console.log(user);

      if (user) {
        const userData = getUserData();
        // Fetch the user's cart from the server when the user is authenticated
        // fetchUserCart(userData);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const saveUserData = (userData) => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      ...userData,
    }));

    localStorage.setItem("userData", JSON.stringify({ ...currentUser, ...userData }));
  };

  const getUserData = () => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : {};
  };

  const emptyCurrentUser = () => {
    setCurrentUser({});
    localStorage.removeItem("userData");
    setCart(null); // Clear the cart when user logs out
  };

  // const fetchUserCart = async (userData) => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/api_cart.php?user_id=${userData.id}`);
  //     console.log(response.status);
  //     if (response.status===200) {
  //       setCart(response.data);
  //     } else {
  //       // If cart doesn't exist for the user, create a new one
  //       const newCart = {
  //         user_id: userData.id,
  //         book_ids: [],
  //         bundle_ids: [],
  //         subscription_id: null,
  //         discount: null,
  //         sub_total: 0,
  //         final_amount: 0,
  //         promo_code: null,
  //       };
  //       await axios.post(`${BASE_URL}/api_cart.php`, newCart);
  //       console.log(newCart);
  //       setCart(newCart);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching cart:", error);
  //   }
  // };
  const fetchUserCart = async (userData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_cart.php?user_id=${userData.id}`);
      console.log(response.status);
      if (response.status === 200) {
        setCart(response.data);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("User cart not found, creating a new cart...");
        const newCart = {
          user_id: userData.id,
          // book_ids: ,
          // bundle_ids: [],
          subscription_id: null,
          discount: null,
          sub_total: 0,
          final_amount: 0,
          promo_code: null,
        };
        
        try {
          console.log(newCart);
          const postResponse = await axios.post(`${BASE_URL}/api_cart.php`, newCart);
          console.log("New cart created:", postResponse.data);
          setCart(newCart);
        } catch (postError) {
          console.error("Error creating new cart:", postError);
        }
      } else {
        console.error("Error fetching cart:", error);
      }
    }
  };
  
  

  const updateCart = async (updatedCart) => {
    try {
      console.log(updatedCart)
      const userData = getUserData();
      const response = await axios.put(`${BASE_URL}/api_cart.php?user_id=${userData.id}`, updatedCart);
      console.log(response.data);
      fetchUserCart(userData);
      // setCart(response.data);
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  // const addToCart = async (item) => {
  //   console.log(item);
  //   const currentvolumeIds = Array.isArray(cart.volumes) ? cart.volumes : convertToArray(cart.volumes) || [];
  //   const currentvolumequantity = Array.isArray(cart.volumes_quantities) ? cart.volumes_quantities : convertToArray(cart.volumes_quantities) || [];
  //   const currentquantity = Array.isArray(cart.quantities) ? cart.quantities : convertToArray(cart.quantities) || [];
  //   const currentBookIds = Array.isArray(cart.book_ids) ? cart.book_ids : convertToArray(cart.book_ids) || [];
  //   if (currentBookIds.includes(parseInt(item.id))) {
  //     toast.error(`${item.title} is already in the cart`);
  //     return;
  //   }
  //   const updatedCart = {
  //     ...cart,
  //     book_ids: [...(Array.isArray(cart.book_ids) ? cart.book_ids : convertToArray(cart.book_ids) || []), parseInt(item.product.id)],
  //     bundle_ids: [...(Array.isArray(cart.bundle_ids) ? cart.bundle_ids : convertToArray(cart.bundle_ids) || [])],
  //      quantities:[...(Array.isArray(cart. quantities) ? cart. quantities : convertToArray(cart. quantities) || item.quantity)],
  //     volumes:[...(Array.isArray(cart.volumes) ? cart.volumes : convertToArray(cart.volumes) || item.items_id)],
  //     volumes_quantities:[...(Array.isArray(cart.volumes_quantities) ? cart.volumes_quantities : convertToArray(cart.volumes_quantities) || item.items_quantity)],
  //     sub_total: parseFloat(cart.sub_total) + parseFloat(item.price),
  //     final_amount: parseFloat(cart.final_amount) + parseFloat(item.price),
  //   };
  //   console.log(updatedCart);
  //   toast.success(`${item.product.title} is added to  the cart`);
  //   updateCart(updatedCart);
  // };
  const addToCart = async (item) => {
    console.log(item);
    // const ensureArray = (value) => {
    //   if (Array.isArray(value)) return value;
    //   if (value === null || value === undefined) return [];
    //   try {
    //     const parsedValue = JSON.parse(value);
    //     if (Array.isArray(parsedValue)) return parsedValue;
    //   } catch (e) {
    //     // value is not a JSON string
    //   }
    //   return [value];
    // };
    const ensureArray = (value) => {
      if (Array.isArray(value)) {
        return value.filter(v => v !== null).map(v => (Array.isArray(v) ? v : [v]));
      }
      if (value === null || value === undefined) return [];
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue)) {
          return parsedValue.filter(v => v !== null).map(v => (Array.isArray(v) ? v : [v]));
        }
      } catch (e) {
        // value is not a JSON string
      }
      return [value];
    };
  
    // Helper function to convert to array if needed
    // const ensureArray = (value) => Array.isArray(value) ? value : (convertToArray(value) || []);
  
    // Current cart data
    const currentVolumeIds = ensureArray(cart.volumes);
    const currentVolumeQuantities = ensureArray(cart.volumes_quantities);
    const currentQuantities = ensureArray(cart.quantities);
    const currentBookIds = ensureArray(cart.book_ids);
  
    // Check if the item is already in the cart
    if (currentBookIds.includes(parseInt(item.product.id))) {
      toast.error(`${item.product.title} is already in the cart`);
      return;
    }
  
    // Update the cart with the new item
    const updatedCart = {
      ...cart,
      book_ids: [...currentBookIds, parseInt(item.product.id)??null],
      bundle_ids: ensureArray(cart.bundle_ids),
      quantities: [...currentQuantities, item.quantity??null],
      volumes: [...currentVolumeIds, item.items_id],
      volumes_quantities: [...currentVolumeQuantities, item.items_quantity],
      sub_total: parseFloat(cart.sub_total) + parseFloat(item.price),
      final_amount: parseFloat(cart.final_amount) + parseFloat(item.price),
    };
  
    console.log(updatedCart);
    toast.success(`${item.product.title} is added to the cart`);
    updateCart(updatedCart);
  };
  const addBundleToCart = async (item) => {
    console.log(item);
    const currentBookIds = Array.isArray(cart.bundle_ids) ? cart.bundle_ids : convertToArray(cart.bundle_ids) || [];
    if (currentBookIds.includes(parseInt(item.id))) {
      toast.error(`${item.bundle_name} is already in the cart`);
      return;
    }
    const updatedCart = {
      ...cart,
      book_ids: [...(Array.isArray(cart.book_ids) ? cart.book_ids : convertToArray(cart.book_ids) || [])],
      bundle_ids: [...(Array.isArray(cart.bundle_ids) ? cart.bundle_ids : convertToArray(cart.bundle_ids) || []), parseInt(item.id)],
      sub_total: parseFloat(cart.sub_total) + parseFloat(item.price),
      final_amount: parseFloat(cart.final_amount) + parseFloat(item.price),
    };
    toast.success(`${item.bundle_name} is added to  the cart`);
    updateCart(updatedCart);
  };

  function convertToArray(input) {
    if (typeof input === 'string') {
      // Remove unwanted characters
      let jsonString = input.replace(/[\[\]\"]/g, '');
  
      // Split by commas and convert to numbers
      let result = jsonString.split(',').map(Number);
      console.log(result);
  
      return result;
    }
    return input; // Return as is if already an array or not a string
  }
  
  const removeFromCart = async (itemId) => {
    const updatedCart = {
      ...cart,
      book_ids: cart.books_ids.filter(id => id !== itemId),
      // sub_total: cart.sub_total - item.price,
      // final_amount: cart.final_amount - item.price,
      // Adjust sub_total and final_amount accordingly, assuming you have item prices available
    };
    updateCart(updatedCart);
  };

  return (
    <AuthContext.Provider value={{ currentUser, cart, saveUserData, getUserData, emptyCurrentUser, addToCart,addBundleToCart, removeFromCart }}>
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
