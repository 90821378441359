import React, { useState, useEffect } from "react";
import axios from 'axios';
import BASE_URL from '../constant';
// Sample data


const Orders = () => {
  const [sampleOrders,setSampleOrder] = useState([
 
  ]);
  const [ orderDetails, setOrderDetails] = useState([
 
  ]);
 
  useEffect(() => {
   
    GetOrder();
  }, []);
  
  const GetOrder = async (orderData) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order successfully fetched:', response.data);
      setSampleOrder(response.data.orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  
  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api_orders.php?id=${orderId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order details fetched:', response.data);
      setOrderDetails(response.data); // Set the specific order details
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };
  const [orders, setOrders] = useState(sampleOrders);
  // const [filteredOrders, setFilteredOrders] = useState(sampleOrders);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);

  // useEffect(() => {
  //   setFilteredOrders(
  //     orders.filter(order =>
  //       order.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       order.itemName.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  // }, [searchTerm, orders]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const closeModal = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Orders</h1>
      <input
        type="text"
        placeholder="Search by user name or item name"
        className="border rounded p-2 mb-4"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-200">
          <tr className="bg-[#fde5c3c4]">
            <th className="border text-left px-4 py-2">User ID</th>
            <th className="border text-left px-4 py-2">Purchase Date</th>
            <th className="border text-left px-4 py-2">Item Name</th>
            <th className="border text-left px-4 py-2">Price</th>
            <th className="border text-left px-4 py-2">View Details</th>
          </tr>
        </thead>
        <tbody>
          {sampleOrders.map(order => (
            <tr key={order.id} className="hover:bg-gray-100 ">
              <td className="border text-left px-4 py-2">OIZVEHADAR-USER-{order.user_id}</td>
              <td className="border text-left px-4 py-2">{order.purchaseDate}</td>
              <td className="border text-left px-4 py-2">{order.order_details[0].message}</td>
              <td className="border text-left px-4 py-2">${order.total_price}</td>
              <td className="border text-left px-4 py-2">
                <button
                  className="bg-[#6D3815] w-full text-white rounded px-2 py-1"
                  onClick={() => handleRowClick(order)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrder && (
        <div className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-[80%] h-[80%]">
            <span className="cursor-pointer float-right" onClick={closeModal}>&times;</span>
            <h2 className="text-xl font-semibold mb-2">Order Details</h2>
            <section class="relative overflow-auto max-h-[500px]">
        <div class="w-full max-w-7xl  px-4 md:px-5 lg-6 mx-auto">
            <h6 class="text-sm text-xl leading-8 text-black mb-3">{selectedOrder.user_id}</h6>
            <div
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-3 sm:gap-8 py-6 border-y border-gray-100 mb-6">
                <div class="box group">
                    <p class="font-normal text-base leading-7 text-gray-500 mb-3 transition-all duration-500 group-hover:text-gray-700">User ID</p>
                    <h6 class="font-semibold font-manrope text-sm leading-9 text-black">OIZVEHADAR-USER-{selectedOrder.id}</h6>
                </div>
                <div class="box group">
                    <p class="font-normal text-base leading-7 text-gray-500 mb-3 transition-all duration-500 group-hover:text-gray-700">Purchase Date</p>
                    <h6 class="font-semibold font-manrope text-sm leading-9 text-black"> {selectedOrder.purchaseDate}</h6>
                </div>
                {/* <div class="box group">
                    <p class="font-normal text-base leading-7 text-gray-500 mb-3 transition-all duration-500 group-hover:text-gray-700">Bundle Purchase</p>
                    <h6 class="font-semibold font-manrope text-sm leading-9 text-black">{selectedOrder.bundle ? "Yes" : "No"}</h6>
                </div> */}
                {/* <div class="box group">
                    <p class="font-normal text-base leading-7 text-gray-500 mb-3 transition-all duration-500 group-hover:text-gray-700">Address</p>
                    <h6 class="font-semibold font-manrope text-sm leading-9 text-black">718 Robbyn Meadow
                    </h6>
                </div> */}
                <div class="box group">
                    <p class="font-normal text-base leading-7 text-gray-500 mb-3 transition-all duration-500 group-hover:text-gray-700">Payment Method</p>
                   Card
                </div>
                
            </div>

            <div class="grid grid-cols-7 w-full pb-6 border-b border-gray-100">
                <div class="col-span-7 min-[500px]:col-span-2 md:col-span-1">
                    <img src="https://oizvehadar.com/api/uploads/images/Books_image0_2024-08-09T22-58-56.png" alt="Skin Care Kit image" class="w-full rounded-xl object-cover"/>
                </div>
                <div
                    class="col-span-7 min-[500px]:col-span-5 md:col-span-6 min-[500px]:pl-5 max-sm:mt-5 flex flex-col justify-center">
                    <div class="flex flex-col min-[500px]:flex-row min-[500px]:items-center justify-between">
                        <div class="">
                            <h5 class="font-manrope font-semibold text-2xl leading-9 text-black mb-6">{selectedOrder.order_details[0].message}
                            </h5>
                            <p class="font-normal text-xl leading-8 text-gray-500">Quantity : <span
                                    class="text-black font-semibold">1</span></p>
                        </div>

                        <h5 class="font-manrope font-semibold text-3xl leading-10 text-black sm:text-right mt-3">
                        ${selectedOrder.order_details[0].price}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center sm:justify-end w-full my-6">
                <div class=" w-full">
                    {/* <div class="flex items-center justify-between mb-6">
                        <p class="font-normal text-xl leading-8 text-gray-500">Subtotal</p>
                        <p class="font-semibold text-xl leading-8 text-gray-900">$240.00</p>
                    </div>           
                    <div class="flex items-center justify-between mb-6">
                        <p class="font-normal text-xl leading-8 text-gray-500">Discount</p>
                        <p class="font-semibold text-xl leading-8 text-gray-900">$50.00</p>
                    </div> */}
                    <div class="flex items-center justify-between py-6 border-y border-gray-100">
                        <p class="font-manrope font-semibold text-2xl leading-9 text-gray-900">Total</p>
                        <p class="font-manrope font-bold text-2xl leading-9 text-indigo-600"> ${selectedOrder.total_price}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
          </div>
        </div>
        
      )}
    </div>
  );
};

export default Orders;
