import React, {useState,useContext,useEffect} from 'react';
import { MdAccountCircle } from "react-icons/md";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from './Home';
import Login from "./Pages/Login";
import Forget from "./Pages/Forget";
import Signup from "./Pages/Signup";
import Collection from "./Pages/Collections";
import Product from './Pages/Product';
import About from "./Pages/About";
import Contact from './Pages/Contact';
import Admin from "./Admin/Admin";
import Bundles from './Pages/Bundles';
import User from './User/User';
import DownloadCatalogue from './Pages/DownloadCatalogue';
import Donate from './Pages/Donate';
import BASE_URL from './constant';
import PDFViewer from './Pages/PdfViewer';
import Subscriptions from './Pages/Subscriptions';
import PdfApp from './Pages/pdfsearch';
import { AuthContext } from './context/AuthContext';
import Stripepayments from './Pages/Stripe';
import ThankYouPage from './Pages/thankyou';
import LanguageSelectorComponent from './LanguageSelector';
import AjaxCart from "./Ajaxcart";
import { useTranslation } from 'react-i18next'; 
import LanguageSelector from "./language-selector";
import Privacy from "./Pages/Privacy";
import Checkout from './Pages/Checkout';
import PrivateRoute from './privateRoute';
const Navbar = () => {
    const { currentUser,getUserData } = useContext(AuthContext);
    const [selectedOption, setSelectedOption] = useState('English');
    useEffect(() => {
        // Check if selectedOption is "Hebrew"
        if (selectedOption === "Hebrew") {
            document.body.classList.add("hebrew");
        } else {
            document.body.classList.remove("hebrew");
        }
    }, [selectedOption]);
    const {t}=useTranslation();
    return (
        <>
        <div className="flex justify-between items-center bg-[#fde5c3c4] shadow-md text-white px-2 p-2" style={{ boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px"}}>
            {/* Left side logo */}
            <div className="logo">
                <img src="https://oizvehadar.com/api/uploads/images/logo.png" className='h-[80px]' alt="logo" />
            </div>
           
            {/* Middle navigation */}
            <div className="navigation flex-grow flex justify-center">
                <NavLink exact to="/" className="mr-4 px-4 text-[12px] py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('Home')}</NavLink>
                <NavLink to="/Collections" className="mr-4 text-[12px] px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('books_catalogue')}</NavLink>
                <NavLink to="/Bundles" className="mr-4 px-4 text-[12px] py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('bundles')}</NavLink>
                <NavLink to="/Subscriptions" className="mr-4 text-[12px] px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('subscriptions')}</NavLink>
                {/* <NavLink to="/About" className="mr-4 px-4 text-[12px] py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">About us</NavLink> */}
                <NavLink to="/Contact" className="mr-4 px-4 text-[12px] py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('contact')}</NavLink>
                <NavLink to="/DownloadCatalogue" className="mr-4 text-[12px] px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('catalogue')}</NavLink>
                <NavLink to="/Donate" className="mr-4 px-4 text-[12px] py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">{t('donate')}</NavLink>
              
            </div>

            
                
                {!currentUser && (
  <>
  {/* <NavLink to="/Pages/Contact" className="mr-4 px-4 py-2 rounded-full bg-red-900 hover:bg-red-950 transition-colors">Contact us</NavLink> */}
    <NavLink  to="/Login" className="mr-2 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors">
      <span className=" text-[12px] text-des w-auto">{t('login')}</span>
    </NavLink>
    <NavLink
      className="mr-4 px-4 text-[12px] py-2 rounded-full bg-[#6D3815] hover:bg-red-950 transition-colors"
      to="/Signup"
    >
      <span className="text-[12px] text-des w-auto">{t('sign')}</span>
    </NavLink>
  </>
)}
                {/* Account circle */}
                {/* <NavLink exact activeClassName="active text-l w-auto" to="/Pages/Login">
                <MdAccountCircle className="ml-3 h-[40px] w-[40px]" style={{color:"000"}} />
                </NavLink> */}
                {currentUser && (
  <> 
  <NavLink  to="/User/User">
  {/* <NavLink exact activeClassName="active text-l w-auto" to="/Pages/Login"> */}
  <MdAccountCircle className="ml-3 h-[40px] w-[40px]" style={{color:"000"}} />
   {/* </NavLink> */}
                </NavLink>
                </>)}
                
                {/* Right side account with dropdown */}
            <div className="relative flex items-center">
               
                <LanguageSelector/>
                <AjaxCart/>
            </div>
        </div>
        <Routes>
    <Route  path='/' Component={Home}/>
    <Route  path='/Login' Component={Login}/>
    <Route  path='/Forget' Component={Forget}/>
    <Route  path='/Signup' Component={Signup}/>
    <Route  path='Collections' Component={Collection}/>
    <Route  path='/Product' Component={Product}/>
    <Route  path='/About' Component={About}/>
    <Route  path='/Contact' Component={Contact}/>
    <Route  path='/Admin/Admin' Component={Admin}/>
    <Route  path='/Bundles' Component={Bundles}/>
    {/* <Route  path='/User' Component={User}/> */}
    <Route  path='/Pages/Stripe' Component={Stripepayments}/>
    <Route  path='/Subscriptions' Component={Subscriptions}/>
    <Route  path='/Pages/Product/:id?' Component={Product}/>
    <Route  path='/DownloadCatalogue' Component={DownloadCatalogue}/>
    <Route  path='/Donate' Component={Donate}/>
    {/* Protected PDFViewer route */}
    <Route 
          path='/pdfViewer/:id?/:title?' 
          element={
            <PrivateRoute>
              <PDFViewer />
            </PrivateRoute>
          } 
        />
    {/* <Route path='/pdfViewer/:id?/:title?' Component={PDFViewer} /> */}
    {/* <Route
          path="/pdfViewer"
          element={<PDFViewer documentUrl={`${BASE_URL}/serve_pdf.php?file=50..pdf`} />}
        /> */}
     <Route  path='/PDFsearch' Component={PdfApp}/>
    <Route  path='/User/User' Component={User}/>
    <Route  path='/thank-you/:paymentConfirmed?/:pro_id?/:product?/:price?/:type?/' Component={ThankYouPage}/>
    <Route  path='/Privacy' Component={Privacy}/>
    <Route  path='/Checkout' Component={Checkout}/>

    </Routes>
        </>
    );
};

export default Navbar;
